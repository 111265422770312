import { Brands } from '@/assets/brands/brands';
import mainTheme from '../theme';

const theme = {
  ...mainTheme,
  name: Brands.BIAWAR,
  COLORS: {
    ...mainTheme.COLORS,
    primary: '#A81524',
    secondary: '#4BB945',
  },
};

export default theme;
