import React, { lazy } from 'react';

import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import PropTypes from 'prop-types';

import SignedInLayout from '@/components/layout/SignInLayout';

import { AnonymousGuard, DefaultLayout, allRoutes } from './config';

const NotFound = lazy(() => import(/* webpackChunkName: "NotFound" */ '@/pages/NotFound'));

export const getRedirectLink = (userInfo) =>
  userInfo?.spId
    ? userInfo?.spSubscription
      ? '/systems'
      : '/no-subscription'
    : userInfo
    ? '/new-service-partner'
    : '/login';

export function Routes({ userInfo }) {
  const routeGenerator =
    ({ layout: Layout = DefaultLayout, page: Page, guard: Guard = AnonymousGuard, ...routeProps }) =>
    // eslint-disable-next-line react/display-name
    (props) => {
      return (
        <Guard userInfo={userInfo} {...routeProps}>
          <Layout {...props}>
            <Page {...props} />
          </Layout>
        </Guard>
      );
    };

  const FallbackLayout = userInfo === null ? DefaultLayout : SignedInLayout;

  return (
    <Switch>
      {allRoutes.map(({ path, ...routeProps }, key) => (
        <Route exact key={key} path={path} render={routeGenerator(routeProps)} />
      ))}

      <Redirect exact key="home" from="/" to={getRedirectLink(userInfo)} />

      <FallbackLayout>
        <Route path="*" key="404" component={NotFound} />
      </FallbackLayout>
    </Switch>
  );
}

Routes.propTypes = {
  userInfo: PropTypes.object,
};

export default connect(({ app: { userInfo } }) => ({
  userInfo,
}))(Routes);
