import {
  GET_FIRMWARES_INFO_REQUEST,
  GET_FIRMWARES_INFO_RESPONSE_SUCCESS,
  GET_FIRMWARE_INFO_REQUEST,
  GET_FIRMWARE_INFO_RESPONSE_SUCCESS,
  GET_FIRMWARE_INFO_RESPONSE_FAIL,
  GET_ACTIVE_BRANDS_REQUEST,
  GET_ACTIVE_BRANDS_RESPONSE_SUCCESS,
  GET_ACTIVE_BRANDS_RESPONSE_FAIL,
} from './actions';

const initialState = {
  loading: false,
  loaded: false,
  firmwaresByBrands: {},
  branfs: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FIRMWARES_INFO_REQUEST:
      return { ...state, loading: true, firmwaresByBrands: {}, loaded: false };
    case GET_FIRMWARES_INFO_RESPONSE_SUCCESS:
      return { ...state, loading: false, firmwaresByBrands: action.firmwaresByBrands, loaded: true };
    case GET_FIRMWARE_INFO_REQUEST:
      return { ...state, loading: true, loaded: false };
    case GET_FIRMWARE_INFO_RESPONSE_SUCCESS:
      return { ...state, loading: false, firmwaresByBrands: action.firmwares, loaded: true };
    case GET_FIRMWARE_INFO_RESPONSE_FAIL:
      return { ...state, loading: false, firmwaresByBrands: [], loaded: true };
    case GET_ACTIVE_BRANDS_REQUEST:
      return { ...state };
    case GET_ACTIVE_BRANDS_RESPONSE_SUCCESS:
      return { ...state, brands: action.brands };
    case GET_ACTIVE_BRANDS_RESPONSE_FAIL:
      return { ...state, brands: [] };
    default:
      return state;
  }
};
