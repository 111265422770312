import { Brands } from '@/assets/brands/brands';
import mainTheme from '../theme';

const theme = {
  ...mainTheme,
  name: Brands.NIBE,
  COLORS: {
    ...mainTheme.COLORS,
    primary: '#318245',
    secondary: '#A81524',
  },
};

export default theme;
