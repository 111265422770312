import {
  CONNECT_USER_REQUEST,
  CONNECT_USER_RESPONSE_SUCCEEDED,
  CONNECT_USER_RESPONSE_FAILED,
  CONNECT_USER_ALREADY_CONNECTED,
  DISCONNECT_USER_REQUEST,
  DISCONNECT_USER_RESPONSE_SUCCEEDED,
  DISCONNECT_USER_RESPONSE_FAILED,
  GET_USERS_REQUEST,
  GET_USERS_RESPONSE,
  PATCH_USER_SECURITY_LEVEL_REQUEST,
  PATCH_USER_SECURITY_LEVEL_RESPONSE_FAILED,
  PATCH_USER_SECURITY_LEVEL_RESPONSE_SUCCEEDED,
  GET_PENDING_SERVICE_PARTNER_REQUEST,
  GET_PENDING_SERVICE_PARTNER_RESPONSE,
  GET_PENDING_SERVICE_PARTNER_RESPONSE_FAILED,
  DELETE_PENDING_SERVICE_PARTNER_INVITE,
  DELETE_PENDING_SERVICE_PARTNER_INVITE_SUCCEEDED,
  DELETE_PENDING_SERVICE_PARTNER_INVITE_FAILED,
  PATCH_PENDING_SERVICE_PARTNER_ROLE_REQUEST,
  PATCH_PENDING_SERVICE_PARTNER_ROLE_RESPONSE_SUCCEEDED,
  PATCH_PENDING_SERVICE_PARTNER_ROLE_RESPONSE_FAILED,
} from './actions';

const initialState = {
  usersLoading: false,
  usersLoaded: false,
  users: [],
  pendingInvites: [],
  userConnectLoading: false,
  userAdded: false,
  userUpdateLoading: false,
  userUpdated: false,
  userRemoveLoading: false,
  userRemoved: false,
  userAlreadyConnected: false,
  invitedUserAdded: false,
  invitesLoading: false,
  spInviteDeleted: false,
  spInviteLoading: false,
  spRoleUpdated: false,
  roleUpdateLoading: false,
};

const updateUserSecurityLevel = (users, userId, role) => {
  return users.map((user) => {
    if (user.id === userId) return { ...user, role: role };
    return { ...user };
  });
};

const removeUserFromState = (users, userId) => {
  return users.filter((u) => u.id !== userId);
};

const removeServicePartnerInviteFromState = (invites, id) => {
  return invites.filter((i) => i.id !== id);
};

const updateServicePartnerRole = (invites, inviteId, role) => {
  return invites.map((invite) => {
    if (invite.id === inviteId) return { ...invite, role: role };
    return { ...invite };
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONNECT_USER_REQUEST:
      return { ...state, userConnectLoading: true, userAdded: false, userAlreadyConnected: false };
    case CONNECT_USER_RESPONSE_SUCCEEDED:
      return { ...state, userConnectLoading: false, userAdded: true, userAlreadyConnected: false };
    case CONNECT_USER_RESPONSE_FAILED:
      return {
        ...state,
        userConnectLoading: false,
        userAdded: false,
        invitedUserAdded: true,
        userAlreadyConnected: false,
      };
    case CONNECT_USER_ALREADY_CONNECTED:
      return { ...state, userConnectLoading: false, userAdded: false, userAlreadyConnected: true };
    case DISCONNECT_USER_REQUEST:
      return { ...state, userRemoveLoading: true, userRemoved: false };
    case DISCONNECT_USER_RESPONSE_SUCCEEDED:
      return {
        ...state,
        userRemoveLoading: false,
        userRemoved: true,
        users: removeUserFromState([...state.users], action.removedUserId),
      };
    case DISCONNECT_USER_RESPONSE_FAILED:
      return { ...state, userRemoveLoading: false, userRemoved: false };
    case GET_USERS_REQUEST:
      return { ...state, usersLoading: true, usersLoaded: false };
    case GET_USERS_RESPONSE:
      return { ...state, usersLoading: false, usersLoaded: true, users: action.users };
    case PATCH_USER_SECURITY_LEVEL_REQUEST:
      return { ...state, userUpdateLoading: true, userUpdated: false };
    case PATCH_USER_SECURITY_LEVEL_RESPONSE_SUCCEEDED:
      return {
        ...state,
        userUpdateLoading: false,
        userUpdated: true,
        users: updateUserSecurityLevel([...state.users], action.userId, action.role),
      };
    case PATCH_USER_SECURITY_LEVEL_RESPONSE_FAILED:
      return { ...state, userUpdateLoading: false, userUpdated: false };
    case GET_PENDING_SERVICE_PARTNER_REQUEST:
      return { ...state, invitesLoading: true, pendingInvites: [] };
    case GET_PENDING_SERVICE_PARTNER_RESPONSE:
      return { ...state, invitesLoading: false, pendingInvites: action.pendingInvites };
    case GET_PENDING_SERVICE_PARTNER_RESPONSE_FAILED:
      return { ...state, invitesLoading: false, pendingInvites: action.pendingInvites };
    case DELETE_PENDING_SERVICE_PARTNER_INVITE:
      return { ...state, spInviteLoading: true, spInviteDeleted: false };
    case DELETE_PENDING_SERVICE_PARTNER_INVITE_SUCCEEDED:
      return {
        ...state,
        spInviteLoading: false,
        spInviteDeleted: true,
        pendingInvites: removeServicePartnerInviteFromState([...state.pendingInvites], action.removedId),
      };
    case DELETE_PENDING_SERVICE_PARTNER_INVITE_FAILED:
      return { ...state, spInviteLoading: false, spInviteDeleted: false };
    case PATCH_PENDING_SERVICE_PARTNER_ROLE_REQUEST:
      return { ...state, roleUpdateLoading: true, spRoleUpdated: false };
    case PATCH_PENDING_SERVICE_PARTNER_ROLE_RESPONSE_SUCCEEDED:
      return {
        ...state,
        roleUpdateLoading: false,
        spRoleUpdated: true,
        pendingInvites: updateServicePartnerRole([...state.pendingInvites], action.inviteId, action.role),
      };
    case PATCH_PENDING_SERVICE_PARTNER_ROLE_RESPONSE_FAILED:
      return { ...state, roleUpdateLoading: false, spRoleUpdated: false };
    default:
      return state;
  }
};
