import * as api from '@/api';
import uuid from 'uuid';
import { FRIDAY, MONDAY, SATURDAY, SUNDAY, dayIndexToDeviceDay, getNextDay } from './utils';
const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
const weekendDays = ['Saturday', 'Sunday'];

const calculateStopDay = (startDay, stopDay, day, weekFormat) => {
  if (startDay === stopDay) {
    return stopDay;
  }
  switch (weekFormat) {
    case 'mon-sun':
      return daysOfWeek[(daysOfWeek.indexOf(day) + 1) % 7];
    case 'mon-fri,sat-sun':
      if (day !== 'Friday' && day !== 'Sunday' && day !== FRIDAY && day !== SUNDAY) {
        return daysOfWeek[(daysOfWeek.indexOf(day) + 1) % 7];
      }
      if (day === 'Friday' || day === FRIDAY) {
        return 'Monday';
      }
      if (day === 'Sunday' || day === SUNDAY) {
        return 'Saturday';
      }
      break;
    case 'mon-fri,sat,sun':
      if (
        day !== 'Friday' &&
        day !== 'Saturday' &&
        day !== 'Sunday' &&
        day !== FRIDAY &&
        day !== SATURDAY &&
        day !== SUNDAY
      ) {
        return daysOfWeek[(daysOfWeek.indexOf(day) + 1) % 7];
      }
      if (day === 'Friday' || day === FRIDAY) {
        return 'Monday';
      }
      if (day === 'Saturday' || day === SATURDAY) {
        return 'Saturday';
      }
      if (day === 'Sunday' || day === SUNDAY) {
        return 'Sunday';
      }
      break;
  }
};

export const GET_MODES_REQUEST = 'scheduling/GET_MODES_REQUEST';
export const GET_MODES_RESPONSE_SUCCESS = 'scheduling/GET_MODES_RESPONSE_SUCCESS';
export const GET_MODES_RESPONSE_ERROR = 'scheduling/GET_MODES_RESPONSE_ERROR';
export const getModes = (deviceId) => async (dispatch) => {
  try {
    dispatch({ type: GET_MODES_REQUEST });
    const config = await api.getScheduleConfig(deviceId);
    const response = await api.getModes(deviceId);
    if (config.data.allowUnscheduled && !config.data.stopTimeAvailable) {
      const modes = [{ modeId: 0, name: 'Unscheduled', settings: [] }, ...response.data];
      dispatch({ type: GET_MODES_RESPONSE_SUCCESS, modes: modes });
    } else {
      dispatch({ type: GET_MODES_RESPONSE_SUCCESS, modes: response.data });
    }
  } catch (e) {
    dispatch({ type: GET_MODES_RESPONSE_ERROR });
  }
};

export const SET_MODES_REQUEST = 'scheduling/SET_MODES_REQUEST';
export const SET_MODES_RESPONSE_SUCCESS = 'scheduling/SET_MODES_RESPONSE_SUCCESS';
export const SET_MODES_RESPONSE_ERROR = 'scheduling/SET_MODES_RESPONSE_ERROR';
export const setModes = (deviceId, modes) => async (dispatch) => {
  try {
    dispatch({ type: SET_MODES_REQUEST });
    await api.setModes(
      deviceId,
      modes.filter((mode) => mode.modeId !== 0)
    );
    dispatch({ type: SET_MODES_RESPONSE_SUCCESS, modes });
  } catch (e) {
    // FIXME: Dead action with no resolution or user preview
    dispatch({ type: SET_MODES_RESPONSE_ERROR });
  }
};

export const GET_WEEKLY_SCHEDULE_REQUEST = 'scheduling/GET_WEEKLY_SCHEDULE_REQUEST';
export const GET_WEEKLY_SCHEDULE_SUCCESS = 'scheduling/GET_WEEKLY_SCHEDULE_SUCCESS';
export const GET_WEEKLY_SCHEDULE_ERROR = 'scheduling/GET_WEEKLY_SCHEDULE_ERROR';
export const getWeeklySchedules = (deviceId) => async (dispatch) => {
  try {
    dispatch({ type: GET_WEEKLY_SCHEDULE_REQUEST });
    const response = await api.getWeeklySchedules(deviceId);

    const weeklySchedules = response.data.map((item) => ({
      ...item,
      events: item.events
        .filter((event) => {
          if (item.weekFormat === 'mon-sun') {
            return event.startDay === 'Monday';
          }
          if (item.weekFormat === 'mon-fri,sat,sun') {
            return event.startDay === 'Sunday' || event.startDay === 'Monday' || event.startDay === 'Saturday';
          }
          if (item.weekFormat === 'mon-fri,sat-sun') {
            return event.startDay === 'Monday' || event.startDay === 'Saturday';
          }
          return true;
        })
        .map((event) => ({
          ...event,
          phantom_id: uuid(),
        })),
    }));

    dispatch({ type: GET_WEEKLY_SCHEDULE_SUCCESS, weeklySchedules });
  } catch (e) {
    dispatch({ type: GET_WEEKLY_SCHEDULE_ERROR });
  }
};

export const SET_WEEKLY_SCHEDULE_REQUEST = 'scheduling/SET_WEEKLY_SCHEDULE_REQUEST';
export const SET_WEEKLY_SCHEDULE_SUCCESS = 'scheduling/SET_WEEKLY_SCHEDULE_SUCCESS';
export const SET_WEEKLY_SCHEDULE_ERROR = 'scheduling/SET_WEEKLY_SCHEDULE_ERROR';
export const setWeeklySchedules = (deviceId, weeklySchedules) => async (dispatch) => {
  try {
    dispatch({ type: SET_WEEKLY_SCHEDULE_REQUEST });
    const expandedWeeklySchedules = weeklySchedules.map(function (schedule) {
      if (schedule.weekFormat === 'mon-sun') {
        const newEvents = [];

        schedule.events.forEach((event) => {
          for (const day of daysOfWeek) {
            if (event.startDay === 'Monday' || event.startDay === MONDAY) {
              newEvents.push({
                ...event,
                startDay: day,
                stopDay: event.stopDay
                  ? event.startTime.localeCompare(event.stopTime) >= 0
                    ? dayIndexToDeviceDay(getNextDay(day))
                    : dayIndexToDeviceDay(day)
                  : event.stopDay,
              });
            }
          }
        });

        const newSchedule = {
          ...schedule,
          events: newEvents,
        };

        return newSchedule;
      }

      if (schedule.weekFormat === 'mon-fri,sat,sun') {
        const newEvents = new Array();
        const newSchedule = {
          ...schedule,
          events: schedule.events.map(function (event) {
            if (event.startDay === 'Monday' || event.startDay === MONDAY) {
              weekDays.forEach((day) =>
                newEvents.push({
                  ...event,
                  startDay: day,
                  stopDay: event.stopDay
                    ? event.startTime.localeCompare(event.stopTime) >= 0
                      ? calculateStopDay(event.startDay, event.stopDay, day, schedule.weekFormat)
                      : day
                    : event.stopDay,
                })
              );
            }
            if (
              event.startDay === 'Saturday' ||
              event.startDay === 'Sunday' ||
              event.startDay === SATURDAY ||
              event.startDay === SUNDAY
            ) {
              newEvents.push({
                ...event,
                startDay: event.startDay,
                stopDay:
                  event.stopDay !== null && event.stopDay !== undefined
                    ? event.startDay !== event.stopDay && event.startTime.localeCompare(event.stopTime) > 0
                      ? calculateStopDay(event.startDay, event.stopDay, event.startDay, schedule.weekFormat)
                      : event.startDay
                    : event.stopDay,
              });
            }
            return newEvents;
          })[0],
        };
        return newSchedule;
      }
      if (schedule.weekFormat === 'mon-fri,sat-sun') {
        const newEvents = new Array();
        const newSchedule = {
          ...schedule,
          events: schedule.events.map(function (event) {
            if (event.startDay === 'Monday' || event.startDay === MONDAY) {
              weekDays.forEach((day) =>
                newEvents.push({
                  ...event,
                  startDay: day,
                  stopDay:
                    event.stopDay !== null && event.stopDay !== undefined
                      ? event.startDay !== event.stopDay && event.startTime.localeCompare(event.stopTime) > 0
                        ? calculateStopDay(event.startDay, event.stopDay, day, schedule.weekFormat)
                        : day
                      : event.stopDay,
                })
              );
            }
            if (event.startDay === 'Saturday' || event.startDay === SATURDAY) {
              weekendDays.forEach((day) =>
                newEvents.push({
                  ...event,
                  startDay: day,
                  stopDay:
                    event.stopDay !== null && event.stopDay !== undefined
                      ? event.startDay !== event.stopDay && event.startTime.localeCompare(event.stopTime) > 0
                        ? calculateStopDay(event.startDay, event.stopDay, day, schedule.weekFormat)
                        : day
                      : event.stopDay,
                })
              );
            }
            return newEvents;
          })[0],
        };
        return newSchedule;
      }
      if (schedule.weekFormat === 'mon,tue,wed,thu,fri,sat,sun') {
        return schedule;
      }
    });
    await api.setWeeklySchedules(deviceId, expandedWeeklySchedules);
    dispatch({
      type: SET_WEEKLY_SCHEDULE_SUCCESS,
      weeklySchedules: expandedWeeklySchedules.map((item) => ({
        ...item,
        events: item.events
          ? item.events.filter((event) => {
              if (item.weekFormat === 'mon-sun') {
                return event.startDay === 'Monday' || event.startDay === MONDAY;
              }
              if (item.weekFormat === 'mon-fri,sat,sun') {
                return (
                  event.startDay === 'Sunday' ||
                  event.startDay === 'Monday' ||
                  event.startDay === 'Saturday' ||
                  event.startDay === SUNDAY ||
                  event.startDay === MONDAY ||
                  event.startDay === SATURDAY
                );
              }
              if (item.weekFormat === 'mon-fri,sat-sun') {
                return (
                  event.startDay === 'Monday' ||
                  event.startDay === 'Saturday' ||
                  event.startDay === MONDAY ||
                  event.startDay === SATURDAY
                );
              }
              return true;
            })
          : [],
      })),
    });
  } catch (e) {
    // FIXME: Raise propper notification and log error to appinsights
    dispatch({ type: SET_WEEKLY_SCHEDULE_ERROR });
    throw e;
  }
};

export const GET_SCHEDULE_CONFIG_REQUEST = 'scheduling/GET_SCHEDULE_CONFIG_REQUEST';
export const GET_SCHEDULE_CONFIG_SUCCESS = 'scheduling/GET_SCHEDULE_CONFIG_SUCCESS';
export const GET_SCHEDULE_CONFIG_ERROR = 'scheduling/GET_SCHEDULE_CONFIG_ERROR';
export const getScheduleConfig = (deviceId) => async (dispatch) => {
  try {
    dispatch({ type: GET_SCHEDULE_CONFIG_REQUEST });
    const response = await api.getScheduleConfig(deviceId);
    dispatch({ type: GET_SCHEDULE_CONFIG_SUCCESS, scheduleConfig: response.data });
  } catch (e) {
    dispatch({ type: GET_SCHEDULE_CONFIG_ERROR });
    throw e;
  }
};

export const SET_VACATION_SCHEDULES_REQUEST = 'scheduling/SET_VACATION_SCHEDULES_REQUEST';
export const SET_VACATION_SCHEDULES_RESPONSE_SUCCESS = 'scheduling/SET_VACATION_SCHEDULES_RESPONSE_SUCCESS';
export const SET_VACATION_SCHEDULES_RESPONSE_ERROR = 'scheduling/SET_VACATION_SCHEDULES_RESPONSE_ERROR';
export const setVacationSchedules = (deviceId, vacationSchedules) => async (dispatch) => {
  try {
    dispatch({ type: SET_VACATION_SCHEDULES_REQUEST });
    await api.setVacationSchedules(deviceId, vacationSchedules);
    dispatch({ type: SET_VACATION_SCHEDULES_RESPONSE_SUCCESS, vacationSchedules });
  } catch (e) {
    dispatch({ type: SET_VACATION_SCHEDULES_RESPONSE_ERROR });
  }
};

export const GET_VACATION_SCHEDULES_REQUEST = 'scheduling/GET_VACATION_SCHEDULES_REQUEST';
export const GET_VACATION_SCHEDULES_SUCCESS = 'scheduling/GET_VACATION_SCHEDULES_SUCCESS';
export const GET_VACATION_SCHEDULES_ERROR = 'scheduling/GET_VACATION_SCHEDULES_ERROR';
export const getVacationSchedules = (deviceId) => async (dispatch) => {
  try {
    dispatch({ type: GET_VACATION_SCHEDULES_REQUEST });
    const response = await api.getVacationSchedules(deviceId);
    dispatch({ type: GET_VACATION_SCHEDULES_SUCCESS, vacationSchedules: response.data });
  } catch (e) {
    dispatch({ type: GET_VACATION_SCHEDULES_ERROR });
  }
};

export const GET_SCHEDULE_MODE_STATUS_REQUEST = 'scheduling/GET_SCHEDULE_MODE_STATUS_REQUEST';
export const GET_SCHEDULE_MODE_STATUS_SUCCESS = 'scheduling/GET_SCHEDULE_MODE_STATUS_SUCCESS';
export const GET_SCHEDULE_MODE_STATUS_ERROR = 'scheduling/GET_SCHEDULE_MODE_STATUS_ERROR';
export const getScheduleModeStatus = (deviceId) => async (dispatch) => {
  try {
    dispatch({ type: GET_SCHEDULE_MODE_STATUS_REQUEST });
    const response = await api.getScheduleModeStatus(deviceId);
    const status = {
      active: response.data.scheduleStatus === 'override',
      modeId: response.data.scheduleMode[0],
    };
    dispatch({ type: GET_SCHEDULE_MODE_STATUS_SUCCESS, scheduleModeStatus: status });
  } catch (e) {
    dispatch({ type: GET_SCHEDULE_MODE_STATUS_ERROR });
  }
};

export const SET_SCHEDULE_OVERRIDE_REQUEST = 'scheduling/SET_SCHEDULE_OVERRIDE_REQUEST';
export const SET_SCHEDULE_OVERRIDE_RESPONSE_SUCCESS = 'scheduling/SET_SCHEDULE_OVERRIDE_RESPONSE_SUCCESS';
export const SET_SCHEDULE_OVERRIDE_RESPONSE_ERROR = 'scheduling/SET_SCHEDULE_OVERRIDE_RESPONSE_ERROR';
export const setScheduleOverride = (deviceId, scheduleOverride) => async (dispatch) => {
  try {
    dispatch({ type: SET_SCHEDULE_OVERRIDE_REQUEST });
    await api.setScheduleOverride(deviceId, scheduleOverride);
    dispatch({ type: SET_SCHEDULE_OVERRIDE_RESPONSE_SUCCESS, scheduleOverride });
  } catch (e) {
    dispatch({ type: SET_SCHEDULE_OVERRIDE_RESPONSE_ERROR });
  }
};

export const SET_SCHEDULE_OVERRIDE_MODE = 'scheduling/SET_SCHEDULE_OVERRIDE_MODE';
export const setScheduleOverrideMode = (modeId) => ({ type: SET_SCHEDULE_OVERRIDE_MODE, modeId });

export const GET_SELECTED_SYSTEM = 'app/GET_SELECTED_SYSTEM';
export const getSelectedSystemById = (groupId) => async (dispatch) => {
  const response = await api.getGroupFullInfo(groupId);
  if (response.status === 200) {
    dispatch({ type: GET_SELECTED_SYSTEM, system: response.data });
  }
};
