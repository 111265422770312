import { Brands } from '@/assets/brands/brands';
import mainTheme from '../theme';

const theme = {
  ...mainTheme,
  name: Brands.METROTHERM,
  COLORS: {
    ...mainTheme.COLORS,
    primary: '#D52B1E',
    secondary: '#364A4F',
  },
};

export default theme;
