export const getFromLocalStorageByKey = (key) => {
  try {
    const persistValue = JSON.parse(localStorage.getItem(key));
    return persistValue;
  } catch (e) {
    return null;
  }
};

export const saveToLocalStorage = (key, value) => {
  if (!key) {
    return;
  }

  localStorage.setItem(key, JSON.stringify(value));
};

export const removeFromLocalStorage = (key) => {
  localStorage.removeItem(key);
};
