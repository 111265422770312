import { Spinner } from 'jpi-cloud-web-ui-kit';
import React from 'react';

import { spinnerDarkColor } from '@/constants';

export interface LoaderProps {
  dark?: boolean;
  size?: number;
  color?: string;
  isPage?: boolean;
  ariaLabel?: string;
}

const pageStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50vh',
};

const style = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '20px',
  marginBottom: '20px',
};

const Loader = ({ dark = true, size = 6.8, color, isPage = false, ariaLabel }: LoaderProps) => (
  <div style={isPage ? pageStyle : style} aria-label={ariaLabel}>
    <Spinner center color={dark ? spinnerDarkColor : color} size={size} />
  </div>
);

// FIXME: Check styled components not applying styles
// const Loader = ({ dark = false }: LoaderProps) => (
//   <CenteredSpinner>
//     <Spinner center color={dark ? spinnerDarkColor : undefined} />
//   </CenteredSpinner>
// );

export default Loader;
