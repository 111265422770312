import configureStore from './configureStore';

const store = configureStore();

// TODO: Temporary fix untill we upgrade redux to v7
/**
 * @description This is not a hook.
 * @package react-redux
 * @returns dispatch
 */
export const useDispatch = () => store.dispatch;

export const useSelector = (selector) => (selector ? selector(store.getState()) : store.getState());

export default store;
