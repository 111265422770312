import axios from 'axios';
import moment from 'moment';

import { logoutUser } from '@/pages/Login/actions';
import { parseJwt } from '@/utils/authentication';
import { deleteAuthTokens, getAuthTokens, isAzureB2CAuth, putAuthTokens } from '@/utils/localStorage/authentication';

import events, { eventEmitter } from '@/events';
import { getAzureB2CLoginLink, reissueAzureB2CTokens } from './azureB2C';

import { reissueAuthToken } from './index';

export const reissueAzureB2CRefreshToken = async (selectedLanguage) => {
  deleteAuthTokens();
  window.location.href = await getAzureB2CLoginLink({
    redirectTo: window.location.pathname,
    language: selectedLanguage,
  });
};

export const reissueLegacyAuthToken = async ({ access_token, refresh_token }) => {
  const spId = parseInt(parseJwt(access_token).spid);
  return await reissueAuthToken(refresh_token, spId);
};

/**
 * If user doesn't have RememberMe option checked upon login,
 * he will have only access_token and no refresh token, thus no need for token re-issue
 * */
export const isNoTokensOrNoRefreshToken = (tokens) => !tokens || !tokens.refresh_token;

// Mechanism to prevent getting multiple tokens from requests when Access Token expires
let isReEnter = false;
let sessionErrorThrottle;

export const emitSessionExpirationWarning = () => {
  // TODO: do NOT Test -> Refactor
  clearTimeout(sessionErrorThrottle);

  sessionErrorThrottle = setTimeout(() => {
    eventEmitter.emit(events.inApp.auth.TOKEN_EXPIRED);
  }, 500);
};

export const requestInterceptorHandler = async (config, store) => {
  const tokens = getAuthTokens();

  const { selectedLanguage } = store.getState().language;
  const langId = selectedLanguage || 'en';
  config.headers['Accept-Language'] = langId;

  if (tokens) {
    const { issuedAt, expires_in, access_token, refresh_token } = tokens;
    if (
      !config.url.includes('/oauth') &&
      moment(issuedAt).add(expires_in, 'seconds').isBefore(moment()) &&
      !isReEnter
    ) {
      try {
        if (isNoTokensOrNoRefreshToken(tokens)) {
          emitSessionExpirationWarning();
          store.dispatch(logoutUser());
          return;
        }

        isReEnter = true;
        const { data } = isAzureB2CAuth()
          ? await reissueAzureB2CTokens(refresh_token)
          : await reissueLegacyAuthToken(tokens);
        putAuthTokens(data);
      } catch (e) {
        if (isAzureB2CAuth()) {
          reissueAzureB2CRefreshToken(langId);
          return;
        }
        store.dispatch(logoutUser());
      } finally {
        isReEnter = false;
      }
    }

    config.headers.Authorization = `Bearer ${access_token}`;
  }

  return config;
};

// FIXME: Refactor and use requestInterceptorHandler in @/api/shopHttpClient
export const responseInterceptorHandler = async (error, store) => {
  if (!error.response) {
    console.warn('[Error Log]: Axios interceptor: Response property on error object is missing: ', error);
  }

  if (error.response?.status === 401) {
    const tokens = getAuthTokens();

    try {
      const { data } = isAzureB2CAuth()
        ? await reissueAzureB2CTokens(tokens.refresh_token)
        : await reissueLegacyAuthToken(tokens);
      putAuthTokens(data);
    } catch (e) {
      if (isAzureB2CAuth()) {
        const { selectedLanguage } = store.getState().language;
        reissueAzureB2CRefreshToken({ language: selectedLanguage });
      } else {
        emitSessionExpirationWarning();
        return store.dispatch(logoutUser());
      }

      return Promise.reject(error);
    }

    return axios(error.config);
  }

  return Promise.reject(error);
};

export const requestInterceptor = (store) => {
  axios.interceptors.request.use((config) => requestInterceptorHandler(config, store));
};

export const responseInterceptor = (store) => {
  axios.interceptors.response.use(null, (error) => responseInterceptorHandler(error, store));
};
