import moment from 'moment';

export const getPredictedPoint = (lastPoint, dateTo) => {
  return { ...lastPoint, timestamp: moment(dateTo).utc().add(10, 'days').format('YYYY-MM-DDTHH:mm:ssZ') };
};

export const determineCurveType = (curveStyle) => {
  switch (curveStyle) {
    case 'smooth':
      return 'curveMonotoneX';
    case 'straight':
      return 'curveLinear';
    case 'binary':
      return 'curveStepAfter';
    default:
      return 'curveMonotoneX';
  }
};

export const calculateYDomain = (minValue, maxValue) => {
  const variationRange = maxValue - minValue;

  const marginValue = variationRange == 0 ? (maxValue / 100) * yDomainMargin : (variationRange / 100) * yDomainMargin;

  return [minValue - marginValue, maxValue + marginValue];
};

export const DISCRETE_COLOR_RANGE = [
  '#1A237E',
  '#FF5722',
  '#33658A',
  '#B41D1D',
  '#ECB322',
  '#6588cd',
  '#66b046',
  '#a361c7',
  '#55a47b',
  '#cb6141',
];

export const chartSeriesType = {
  line: 'Line',
  column: 'Column',
  area: 'Area',
};
const yDomainMargin = 10;
export const defaultValueLowerLimit = -32760;

export const month = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const daysInYear = (year) => {
  return (year % 4 === 0 && year % 100 > 0) || year % 400 == 0 ? 366 : 365;
};

export const checkTodayDate = (date) => {
  const d = moment();
  return d.format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD');
};

export const checkTwoYearsBackDate = (date) => {
  const d = moment();
  d.subtract(2, 'years');
  d.startOf('year');
  return d.format('DD/MM/YYYY') === moment(date).format('DD/MM/YYYY');
};

export const getCurrentDateFormat = (DateRange, range, date) => {
  switch (range) {
    case DateRange.Year:
      return moment(date).format('YYYY');
    case DateRange.Month:
      return month[moment(date).month()];
    case DateRange.Week:
      return moment(date).week();
    default:
      return moment(date).format('DD/MM/YYYY');
  }
};

export const chartNumber = ['first', 'second'];

export const checkIsFirstChart = (num) => num === chartNumber[0];
export const formatDate = (d) => moment(d).utc().format('YYYY-MM-DD HH:mm:ss');

export const maxLengthForChartSensors = 10;

export const getColor = (color, isActive = true) => (isActive ? color : '#E2E2E2');

import { DateRange } from './actions';
import { applyTickFormat } from './components/chart/tick-format';

export const setCustomDateRange = (dateRange, dateFrom, dateTo, stepBack) => {
  const minDate = moment(dateFrom);
  let maxDate = moment(dateTo);
  const today = moment();
  const d = moment();

  switch (dateRange) {
    case DateRange.Year: {
      if (!stepBack && today.year() !== minDate.year()) {
        minDate.add(1, 'year');
        if (minDate.year() === today.year()) {
          maxDate = moment();
        } else {
          maxDate.add(1, 'year');
        }
      }
      if (stepBack && !checkTwoYearsBackDate(minDate)) {
        minDate.subtract(1, 'year');
        minDate.startOf('year');
        if (maxDate.year() !== today.year()) {
          maxDate.subtract(1, 'year');
        } else {
          maxDate = moment(minDate).endOf('year');
        }
      }

      break;
    }
    case DateRange.Month: {
      if (!stepBack && !checkTodayDate(maxDate)) {
        minDate.add(1, 'month');
        if (today.format('MMM YYYY') === minDate.format('MMM YYYY')) {
          maxDate = moment();
        } else {
          maxDate.add(1, 'month');
        }
      }
      if (stepBack && !checkTwoYearsBackDate(minDate)) {
        minDate.subtract(1, 'month');
        if (maxDate.month() !== today.month()) {
          maxDate.subtract(1, 'month');
        } else {
          maxDate = moment(minDate).endOf('month');
        }
      }

      break;
    }
    case DateRange.Week: {
      if (!stepBack && !checkTodayDate(maxDate)) {
        minDate.add(1, 'week');
        if (moment(d).week() === moment(minDate).week()) {
          maxDate = today;
        } else {
          maxDate = moment(minDate).endOf('week');
        }
      }
      if (stepBack && !checkTwoYearsBackDate(minDate)) {
        minDate.subtract(1, 'week');
        if (!checkTodayDate(maxDate)) {
          maxDate.subtract(1, 'week');
        } else {
          maxDate = moment(minDate).endOf('week');
        }
      }
      break;
    }
    default: {
      if (!stepBack && !checkTodayDate(minDate)) {
        minDate.add(1, 'day');
        if (checkTodayDate(minDate)) {
          maxDate = today;
        } else {
          maxDate.add(1, 'day');
          maxDate = moment(maxDate).endOf('day');
        }
      }
      if (stepBack && !checkTwoYearsBackDate(minDate)) {
        minDate.subtract(1, 'day');
        if (!checkTodayDate(maxDate)) {
          maxDate.subtract(1, 'day');
        } else {
          maxDate = moment(minDate).endOf('day');
        }
      }
      break;
    }
  }
  return { minDate, maxDate };
};

export const setDateRangeForDifferentTimePeriod = (range) => {
  const dateTo = moment();
  let dateFrom;

  switch (range) {
    case DateRange.Year:
      dateFrom = moment().startOf('year');
      break;
    case DateRange.Month:
      dateFrom = moment().startOf('month');
      break;
    case DateRange.Week:
      dateFrom = moment().startOf('week');
      break;
    case DateRange.Custom:
      dateFrom = moment().subtract(2, 'years');
      break;
    case 'nonPremiumUserCustom':
      dateFrom = moment().subtract(1, 'month');
      break;
    default:
      dateFrom = moment().startOf('day');
      break;
  }
  return { dateTo, dateFrom };
};

const getNearestChartPointBeforeOrEqual = (points, value) => {
  if (!points || points.length === 0) {
    return null;
  }

  let nearestPoint = null;
  for (const point of points) {
    if (moment(point.timestamp).isAfter(value)) {
      break;
    }
    nearestPoint = point;
  }
  return nearestPoint;
};

export const onHover = (value, charts, parameters) => {
  const hoveredValues = {
    x: moment(value.x),
    values: charts.map((c) => {
      const hoveredPoint = getNearestChartPointBeforeOrEqual(c.points, value.x);
      if (hoveredPoint) {
        const enumValueText = parameters
          ?.find((p) => p.id === c.category)
          ?.parameters?.find((p) => p.parameterId === c.parameterId)
          ?.enumValues?.find((v) => +v.value === hoveredPoint.value)?.text;

        if (enumValueText) {
          return {
            title: c.parameter,
            value: enumValueText,
          };
        }

        const value = (Math.round(hoveredPoint.value * 100) / 100).toFixed(hoveredPoint.decimals);
        const formattedValue = value <= defaultValueLowerLimit ? '--' : `${value} ${c.unit}`;

        return {
          title: c.parameter,
          value: formattedValue,
        };
      }
    }),
  };

  return hoveredValues;
};

export const calculateXDomain = (dateRange, dateFrom, dateTo) => {
  const startDate = moment(dateFrom),
    endDate = moment(dateTo);

  // X domain is extended so values located close to edges
  // are not cut off by Borders component.
  // These values are also slightly modified for each date range
  // to make labels fit. Will probably be reworked
  // during CLOUDTEAM-2945.
  switch (dateRange) {
    case 'Day':
      startDate.subtract(3.5, 'minutes');
      endDate.add(3.5, 'minutes');
      break;
    case 'Week':
      startDate.subtract(75, 'minutes');
      endDate.add(75, 'minutes');
      break;
    case 'Month':
      startDate.subtract(12, 'hours');
      endDate.add(12, 'hours');
      break;
    case 'Year':
      startDate.subtract(5, 'days');
      endDate.add(5, 'days');
      break;
  }

  return [startDate.toDate(), endDate.toDate()];
};

export const findBarWidth = (date, dateRange) => {
  switch (dateRange) {
    case DateRange.Day:
      date.subtract(5, 'minutes');
      break;
    case DateRange.Week:
      date.subtract(30, 'minutes');
      break;
    case DateRange.Month:
      date.subtract(6, 'hours');
      break;
    case DateRange.Year:
      date.subtract(5, 'days');
      break;
    case DateRange.Custom:
      date.subtract(10, 'days');
      break;
  }
  return date;
};

export const translateXAxisLabel = (input) => {
  return applyTickFormat(input);
};

export const findCurveStyle = (chart, parameters) => {
  const p = parameters.find((res) => res.id === chart.category);
  return p ? p.parameters.find((res) => res.parameterName === chart.parameter).curveStyle : 'smooth';
};

export const findTimeDiff = (endDate, startDate, timeDiffRange) => {
  const timeDifference = Math.ceil(moment.duration(endDate.diff(startDate)).asHours());
  const timeDifferencePercentage = ((timeDiffRange * 2) / 100) * timeDifference;
  return timeDifferencePercentage;
};

export const findScrollWidth = (dateRange, isLeft, selectedZoom, dateFrom, dateTo) => {
  const startDate = moment(selectedZoom.left);
  const endDate = moment(selectedZoom.right);

  switch (dateRange) {
    case DateRange.Day:
      isLeft ? startDate.subtract(5, 'minutes') : startDate.add(5, 'minutes');
      isLeft ? endDate.subtract(5, 'minutes') : endDate.add(5, 'minutes');
      break;
    case DateRange.Week:
      isLeft ? startDate.subtract(5 * 3, 'minutes') : startDate.add(5 * 3, 'minutes');
      isLeft ? endDate.subtract(5 * 3, 'minutes') : endDate.add(5 * 3, 'minutes');
      break;
    case DateRange.Month:
      isLeft ? startDate.subtract(5, 'hours') : startDate.add(5, 'hours');
      isLeft ? endDate.subtract(5, 'hours') : endDate.add(5, 'hours');
      break;
    case DateRange.Year:
      isLeft ? startDate.subtract(5 * 3, 'hours') : startDate.add(5 * 3, 'hours');
      isLeft ? endDate.subtract(5 * 3, 'hours') : endDate.add(5 * 3, 'hours');
      break;
    case DateRange.Custom:
      isLeft ? startDate.subtract(1, 'days') : startDate.add(1, 'days');
      isLeft ? endDate.subtract(1, 'days') : endDate.add(1, 'days');
      break;
  }
  if (startDate.isAfter(dateFrom) && endDate.isBefore(dateTo)) {
    return { left: startDate, right: endDate };
  }
  return selectedZoom;
};

export const generatePointSeries = (chart, range) => {
  const points = chart.precedingPoint ? [chart.precedingPoint, ...chart.points] : chart.points;

  return points.map((p) => {
    const yValue = Number(Number(p.value).toFixed(p.decimals));
    const hasValidYValue = p.value !== null && p.value !== undefined && yValue >= defaultValueLowerLimit;
    if (chart.chartType === chartSeriesType.column) {
      return {
        x0: moment.utc(new Date(findBarWidth(moment(p.timestamp), range)).toISOString()),
        x: moment(p.timestamp),
        y: hasValidYValue ? yValue : null,
      };
    }
    return {
      x: moment(p.timestamp),
      y: hasValidYValue ? yValue : null,
    };
  });
};

export const onChangeChart = (chart, chartType, parameters) =>
  parameters.map((param) =>
    param.parameterId === chart.parameterId ? { ...param, ...chart, chartType: chartType } : param
  );

export const crosshairIsInSelectedRange = (hoveredValues, selectedZoom) => {
  if (!hoveredValues) {
    return false;
  }

  if (!selectedZoom) {
    return true;
  }

  return hoveredValues.x > selectedZoom.left && hoveredValues.x < selectedZoom.right;
};

export const nonPremiumNumberOfDays = 30;

export const checkForAvailableColors = (colors) => {
  return [...DISCRETE_COLOR_RANGE].filter((_) => !Object.values(colors).includes(_));
};
