import { weekStartsOnSunday, alpha2CountryCodesWhereAmPmHours } from '../constants/countries';
import timeZoneToAlpha2CountryCode from '../constants/timeZoneToAlpha2CountryCode.json';

export const isWeekStartsOnSunday = (countryCode) => {
  if (!countryCode) return false;

  return weekStartsOnSunday.includes(countryCode);
};

export const isCountryUseAmPmHours = (alpha2CountryCode) => {
  if (!alpha2CountryCode) return false;

  return alpha2CountryCodesWhereAmPmHours.includes(alpha2CountryCode);
};

export const getCurrentCountryCode = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  return timeZoneToAlpha2CountryCode[timeZone];
};
