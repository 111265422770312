import { Brands } from '@/assets/brands/brands';
import mainTheme from '../theme';

const theme = {
  ...mainTheme,
  name: Brands.NOVELAN,
  COLORS: {
    ...mainTheme.COLORS,
    primary: '#798C45',
    secondary: '#1D2525',
  },
};

export default theme;
