import axios from 'axios';
import { API_URL } from './index';
import { EL_PRICE_PARAM_ID } from '@/components/constants';

export const getPoints = async (deviceId, parameterId, range, method, dateFrom, dateTo) => {
  const validateStatus = (s) => s === 200 || s === 400;
  const url = `${API_URL}/v2/devices/${deviceId}/points/${parameterId}/${dateFrom}/${dateTo}/${method}/${range}`;
  const res = await axios.get(url, { validateStatus });
  return res.status === 200 ? res.data : [];
};

export const getPrecedingPoint = async (deviceId, parameterId, date) => {
  if (parameterId === EL_PRICE_PARAM_ID) return null;

  try {
    const res = await axios.get(`${API_URL}/v2/devices/${deviceId}/points/${parameterId}/preceding/${date}`);
    return res.status === 200 ? res.data : null;
  } catch (err) {
    console.warn(err);
    return null;
  }
};
