import { HIDE_GROUPING_BUTTON_FROM_BRANDS } from '../constants';

export const checkGroupingAvailability = (servicePartner) => {
  const ENV = process.env.ENV || '';
  const servicePartnerBrandIds =
    servicePartner && servicePartner.brands ? servicePartner.brands.map((brand) => brand.brandId) : [];
  const isBrandAvailable =
    servicePartnerBrandIds.filter((brand) => HIDE_GROUPING_BUTTON_FROM_BRANDS.includes(brand)).length > 0;
  return !isBrandAvailable || ENV !== 'production';
};
