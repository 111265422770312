import {
  GET_DEVICES_REQUEST,
  GET_DEVICES_RESPONSE,
  GET_DEVICES_FAILED,
  SET_DEVICE_UPDATE_AVAILABLE,
  SET_SELECTED_DEVICE,
  SET_DEVICE_FIRMWARE_LOADING_STATUS,
  GET_DEVICE_FIRMWARE_UPDATE_STATUS_RESPONSE,
  GET_DEVICE_FIRMWARE_UPDATE_STATUS_FAILED,
  HIDE_CONFIRM_ON_DEVICE,
  CHANGE_SELECTED_TAB,
} from './actions';

const initialState = {
  loading: false,
  devices: [],
  deviceUpdateAvailable: false,
  selectedDevice: null,
  updateInfo: null,
  loadingUpdateInfo: true,
  selectedTab: 'status',
  isConfirmOnDeviceShow: false,
  statusRefreshing: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DEVICES_REQUEST:
      return { ...state, loading: true, devices: [] };
    case GET_DEVICES_RESPONSE:
      return { ...state, loading: false, devices: action.devices };
    case GET_DEVICES_FAILED:
      return { ...state, loading: false, devices: [] };
    case SET_DEVICE_UPDATE_AVAILABLE:
      return { ...state, deviceUpdateAvailable: action.status };
    case SET_SELECTED_DEVICE:
      return {
        ...state,
        selectedDevice: action.device,
        loadingUpdateInfo: true,
      };
    case SET_DEVICE_FIRMWARE_LOADING_STATUS:
      return { ...state, loadingUpdateInfo: action.loadingUpdateInfo, statusRefreshing: action.statusRefreshing };
    case GET_DEVICE_FIRMWARE_UPDATE_STATUS_RESPONSE:
      return { ...state, updateInfo: action.updateInfo, loadingUpdateInfo: false, statusRefreshing: false };
    case GET_DEVICE_FIRMWARE_UPDATE_STATUS_FAILED:
      return { ...state, updateInfo: null, loadingUpdateInfo: false, statusRefreshing: false };
    case HIDE_CONFIRM_ON_DEVICE:
      return { ...state, isConfirmOnDeviceShow: false };
    case CHANGE_SELECTED_TAB:
      return { ...state, selectedTab: action.selectedTab };
    default:
      return state;
  }
};
