import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// TODO: Refactor and use Link from router
function Link({ to, children, tagName: TagName = 'div', preNavigationCallback, ...rest }) {
  const { push } = useHistory();

  const handleClick = (e) => {
    preNavigationCallback?.();

    if (!to) return e.preventDefault();

    push(to);
  };

  return (
    <TagName role="link" onClick={handleClick} {...rest}>
      {children}
    </TagName>
  );
}

Link.propTypes = {
  to: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  preNavigationCallback: PropTypes.func,
  tagName: PropTypes.string,
};

export default Link;
