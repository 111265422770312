import { AUTH_ACCESS_TOKEN, CONSUMER_TOKEN, PERSIST } from '../../components/constants';
import { getFromLocalStorageByKey, removeFromLocalStorage, saveToLocalStorage } from './index';

export const setPersist = (isPersist) => {
  saveToLocalStorage(PERSIST, isPersist);
};

export const isPersist = () => {
  return getFromLocalStorageByKey(PERSIST);
};

export const getAuthTokens = () => {
  return getFromLocalStorageByKey(isAzureB2CAuth() ? AZURE_B2C_TOKENS : AUTH_ACCESS_TOKEN);
};

export const putAuthTokens = (tokens) => {
  localStorage.setItem(
    isAzureB2CAuth() ? AZURE_B2C_TOKENS : AUTH_ACCESS_TOKEN,
    JSON.stringify({ ...tokens, issuedAt: new Date() })
  );
};

export const deleteAuthTokens = () => {
  removeFromLocalStorage(isAzureB2CAuth() ? AZURE_B2C_TOKENS : AUTH_ACCESS_TOKEN);
  setPersist(false);
  removeFromLocalStorage(IS_AZURE_B2C_AUTH);
};

export const getTempTokens = () => {
  return getFromLocalStorageByKey(CONSUMER_TOKEN);
};

export const putTempTokens = (tokens) => {
  if (tokens !== null) {
    localStorage.setItem(CONSUMER_TOKEN, JSON.stringify({ ...tokens, issuedAt: new Date() }));
  }
};

export const deleteTempTokens = () => {
  removeFromLocalStorage(CONSUMER_TOKEN);
};

export const IS_AZURE_B2C_AUTH = 'IS_AZURE_B2C_AUTH';
export const AZURE_B2C_TOKENS = 'AZURE_B2C_TOKENS';

export const AZURE_B2C_CODE_VERIFIER = 'AZURE_B2C_CODE_VERIFIER';

// TODO: Remove this functionality after migration to Azure B2C
export const setAzureB2CAuth = () => saveToLocalStorage(IS_AZURE_B2C_AUTH, true);
export const isAzureB2CAuth = () => !!getFromLocalStorageByKey(IS_AZURE_B2C_AUTH);

export const getCodeVerifierAndRemoveFromLocalStorage = () => {
  const codeVerifier = getFromLocalStorageByKey(AZURE_B2C_CODE_VERIFIER);
  removeFromLocalStorage(AZURE_B2C_CODE_VERIFIER);
  return codeVerifier;
};
