import * as api from '@/api';
import * as lastUsedLanguage from '@/api/language';
import { getCultureByLanguage } from '@/localization/cultures';
import { timeFormatDefaultLocale } from 'd3-time-format';
import moment from 'moment';
import { reloadTranslations } from '../../../text-db/actions';

export const LANGUAGE_SELECTED = 'language/LANGUAGE_SELECTED';
export const selectLanguage = (language, userInfo) => async (dispatch) => {
  const culture = getCultureByLanguage(language);

  timeFormatDefaultLocale(culture.d3TimeLocale);
  moment.locale(culture.momentLocale);

  if (userInfo && userInfo?.id && !userInfo?.isDemo) {
    await api.updateUserLanguage(userInfo, culture.language);
  }

  dispatch(reloadTranslations(culture.language));

  dispatch({
    type: LANGUAGE_SELECTED,
    selectedLanguage: culture.language,
    languageTag: culture.languageTag,
  });

  lastUsedLanguage.set(culture.language);
};
