import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { createBrowserHistory } from 'history';
import { useEffect } from 'react';

import events, { eventEmitter } from '@/events';

/** Documentaiton: 
  https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin
  https://github.com/microsoft/ApplicationInsights-JS#readme
*/
const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    // TODO: Setup debug mode
    instrumentationKey: process.env.APPINSIGHTS_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

appInsights.loadAppInsights();

// Duplicate: https://github.com/3100-JPI-Cloud-Team/jpi-cloud-web/blob/0006b1e6631caa1de1cd9dec2b95eee0e4340ec0/jpi-cloud-web/src/components/tracking/AppInsights.js
const AppInsights = ({ children = null }) => {
  const logEvent = (name) => {
    // eslint-disable-next-line no-console
    console.log('logEvent: ', name);
    appInsights.trackEvent({ name });
  };

  const logView = () => {
    appInsights.pageView();
  };

  const logError = (trace = {}) => {
    appInsights.trackException(trace);
  };

  useEffect(() => {
    appInsights.trackPageView();
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.log('AppInsights setup!');

    eventEmitter.on(events.appInsights.track, logEvent);
    eventEmitter.on(events.appInsights.pageView, logView);
    eventEmitter.on(events.appInsights.logError, logError);

    return () => {
      eventEmitter.off(events.appInsights.track, logEvent);
      eventEmitter.off(events.appInsights.pageView, logView);
      eventEmitter.off(events.appInsights.logError, logError);
    };
  }, []);

  return children;
};

// TODO: Check for this tracking
export const withAppInsights = (Component) => withAITracking(reactPlugin, Component);

export default AppInsights;
