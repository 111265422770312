import { NotificationManager } from 'react-notifications';

import * as api from '@/api';
import { selectSystem } from '@/components/AppInitializer/actions';
import events, { eventEmitter } from '@/events';

export const GET_SYSTEM_STATUS_REQUEST = 'systemStatusTab/GET_SYSTEM_STATUS_REQUEST';
export const GET_SYSTEM_STATUS_RESPONSE = 'systemStatusTab/GET_SYSTEM_STATUS_RESPONSE';
export const DISCONNECT_SYSTEM_REQUEST = 'systemStatusTab/DISCONNECT_SYSTEM_REQUEST';
export const DISCONNECT_SYSTEM_RESPONSE = 'systemStatusTab/DISCONNECT_SYSTEM_RESPONSE';

export const getSystemStatus = (groupId) => async (dispatch) => {
  dispatch({ type: GET_SYSTEM_STATUS_REQUEST });
  const response = await api.getGroupFullInfo(groupId);
  if (response.status === 200) {
    dispatch({ type: GET_SYSTEM_STATUS_RESPONSE, system: response.data });
  }
};

export const ACTIVE_ALARMS_STATUS_RESPONSE = 'systemStatusTab/ACTIVE_ALARMS_STATUS_RESPONSE';
export const doesSystemHaveActiveAlarm = (groupId) => async (dispatch) => {
  const response = await api.hasActiveAlarm(groupId);
  if (response.status === 200) {
    dispatch({ type: ACTIVE_ALARMS_STATUS_RESPONSE, alarm: response.data.alarm });
  }
};

export const GET_SYSTEM_INFORMATION_REQUEST = 'systemStatusTab/GET_SYSTEM_INFORMATION_REQUEST';
export const GET_SYSTEM_INFORMATION_RESPONSE = 'systemStatusTab/GET_SYSTEM_INFORMATION_RESPONSE';

export const getSystemInformation = (groupId, spId) => async (dispatch) => {
  dispatch({ type: GET_SYSTEM_INFORMATION_REQUEST });
  const categories = await api.getAllParameters(groupId);
  const points = categories.map((c) => c.parameters.map((p) => p.parameterId));
  const flatPoints = points.reduce((acc, point) => acc.concat(point), []);
  const deviceId =
    (categories &&
      categories[0] &&
      categories[0].parameters &&
      categories[0].parameters[0] &&
      categories[0].parameters[0].deviceId) ||
    '';
  const parameters = deviceId && flatPoints ? await api.getDeviceParameterValue(deviceId, flatPoints) : { data: [] };
  const tags = await api.getServicePartnerSystemTags(spId, groupId);
  const response = {
    categories: categories,
    points: flatPoints,
    parameters: parameters.data,
    tags: tags.data,
  };
  dispatch({ type: GET_SYSTEM_INFORMATION_RESPONSE, response: response });
};

export const getOperatingInformation = (groupId, deviceId, spId) => async (dispatch) => {
  dispatch({ type: GET_SYSTEM_INFORMATION_REQUEST });
  try {
    const response = await api.getOperatingInformation(groupId, deviceId, spId);
    if (response.status === 200) {
      const points = response.data.points.map(Number);
      const data = { ...response.data, points };
      dispatch({ type: GET_SYSTEM_INFORMATION_RESPONSE, response: data });
    }
    // TODO: Process other cases
  } catch (error) {
    NotificationManager.error('An unexpected error has occurred. Try again later.');
    eventEmitter.emit(events.appInsights.logError, error);

    // dispatch({ type: GET_SYSTEM_INFORMATION_RESPONSE, response: {} });
  }
};

export const GET_CUSTOMER_INFORMATION_REQUEST = 'systemStatusTab/GET_CUSTOMER_INFORMATION_REQUEST';
export const GET_CUSTOMER_INFORMATION_RESPONSE = 'systemStatusTab/GET_CUSTOMER_INFORMATION_RESPONSE';

export const getCustomerInformation = (groupId, spId) => async (dispatch) => {
  dispatch({ type: GET_CUSTOMER_INFORMATION_REQUEST });
  const customer = await api.getCustomerInformation(spId, groupId);
  dispatch({ type: GET_CUSTOMER_INFORMATION_RESPONSE, customer: customer.data });
};

export const RENAME_SYSTEM_REQUEST = 'systemStatusTab/RENAME_SYSTEM_REQUEST';
export const RENAME_SYSTEM_RESPONSE_SUCCEEDED = 'systemStatusTab/RENAME_SYSTEM_RESPONSE_SUCCEEDED';
export const RENAME_SYSTEM_REQUEST_FAILED = 'systemStatusTab/RENAME_REQUEST_SYSTEM_FAILED';
export const renameSystemName = (spId, system, name) => async (dispatch) => {
  const errors = {
    404: 'system_not_found',
    403: 'rename_system_forbidden',
    unknown: 'unknown',
  };

  dispatch({ type: RENAME_SYSTEM_REQUEST });

  try {
    const response = await api.changeSystemName(spId, system.id, name);
    if (response.status === 200) {
      dispatch(getSystemStatus(system.id));
      dispatch({ type: RENAME_SYSTEM_RESPONSE_SUCCEEDED });
      return true;
    }
  } catch (e) {
    if (e.response) {
      dispatch({
        type: RENAME_SYSTEM_REQUEST_FAILED,
        error: errors[e.response.status] || errors.unknown,
      });
      return false;
    }
  }

  dispatch({ type: RENAME_SYSTEM_REQUEST_FAILED, error: errors.unknown });
  return false;
};

export const RESET_SYSTEM_FORM_ERROR_REQUEST = 'systemStatusTab/RESET_SYSTEM_FORM_ERROR_REQUEST';
export const resetSystemError = () => (dispatch) => dispatch({ type: RESET_SYSTEM_FORM_ERROR_REQUEST });

export const CLEAR_SYSTEM_INFORMATION_RESPONSE = 'systemStatusTab/CLEAR_SYSTEM_INFORMATION_RESPONSE';

export const clearSystemInformation = () => (dispatch) => {
  dispatch({ type: CLEAR_SYSTEM_INFORMATION_RESPONSE });
};

export const ADD_SYSTEM_TAG_RESPONSE = 'systemStatusTab/ADD_SYSTEM_TAG_RESPONSE';

export const addTag = (spId, groupId, tag) => async (dispatch) => {
  const response = await api.addServicePartnerSystemTag(spId, groupId, tag);
  if (response.status === 200) {
    const result = {
      tag: tag,
      groupId: groupId,
    };
    dispatch({ type: ADD_SYSTEM_TAG_RESPONSE, result: result });
  }
};

export const DELETE_SYSTEM_TAG_RESPONSE = 'systemStatusTab/DELETE_SYSTEM_TAG_RESPONSE';

export const deleteTag = (spId, groupId, tag) => async (dispatch) => {
  const response = await api.deleteServicePartnerSystemTag(spId, groupId, tag);
  if (response.status === 200) {
    const result = {
      tag: tag,
      groupId: groupId,
    };
    dispatch({ type: DELETE_SYSTEM_TAG_RESPONSE, result: result });
  }
};

export const UPDATE_SYSTEM_TAG_RESPONSE = 'systemStatusTab/UPDATE_SYSTEM_TAG_RESPONSE';

export const updateTags = (spId, groupId, tags) => async (dispatch) => {
  const response = await api.updateServicePartnerTags(spId, groupId, tags);
  if (response.status === 200) {
    dispatch({ type: UPDATE_SYSTEM_TAG_RESPONSE, tags });
  }
};

export const disconnectServicePartnerSystem = (accountId, system) => async (dispatch) => {
  dispatch({ type: DISCONNECT_SYSTEM_REQUEST });

  const response = await api.disconnectServicePartnerSystem(accountId, system);
  if (response.status === 200) {
    dispatch(selectSystem(null));
    dispatch({ type: DISCONNECT_SYSTEM_RESPONSE, system });
  }
};
