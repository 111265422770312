import { Brands } from '@/assets/brands/brands.js';

// TODO: Transfer to enum
export const ALARM_STATUSES = {
  ACTIVE: 'Active',
  DISMISSED_BY_DEVICE: 'DismissedByDevice',
  RESET_BY_USER_ON_DEVICE: 'ResetByUserOnDevice',
  RESET_BY_USER_FROM_CLOUD: 'ResetByUserFromCloud',
};

export const ALARM_TYPES = {
  ALL: 'all',
  ACTIVE: 'active',
};

export const ELIGIBLE_BRANDS_FOR_HIDING_HISTORY = [Brands.AIT, Brands.NOVELAN, Brands.CTA];

export const BRANDS_WITH_NOEM_FIRMWARE = [Brands.AIT, Brands.NOVELAN, Brands.CTA];

export const resetAlarmErrorMessages = {
  404: {
    id: 'alarms.error.request.reset-alarm.device-not-found',
    defaultMessage: 'The alarm can\'t be reset. Check that the device is registered in the system.',
  },
  409: {
    id: 'alarms.error.request.reset-alarm.device-disconnected',
    defaultMessage: 'The alarm can\'t be reset. Check that the device is connected.',
  },
};

export const activateAidModeErrorMessages = {
  404: {
    id: 'alarms.error.request.activate-aid-mode.device-not-found',
    defaultMessage: 'Can\'t activate aid mode. Check that device is registered in system.',
  },
  409: {
    id: 'alarms.error.request.activate-aid-mode.device-disconnected',
    defaultMessage: 'Can\'t activate aid mode. Check that device is connected.',
  },
};

export const unknownErrorMessage = {
  id: 'generic.error.request.unknown',
  defaultMessage: 'An error has occurred. Try again later.',
};
