import React from 'react';

import PropTypes from 'prop-types';

import DesktopMenuItem from './components/DesktopMenuItem';

import UserPermissionsContext from '../../UserPermissionsContext';

import './desktop-menu.scss';

export const DesktopMenu = ({ menuItems, selectedSystem, logoutUser }) => (
  <UserPermissionsContext.Consumer>
    {(context) => (
      <nav aria-label="Menu" className="desktopMenu">
        <ul className="desktopMenu__list">
          {menuItems
            .filter(
              (menuItem) =>
                menuItem.visible?.() ||
                menuItem.children.some((subItem) => subItem.visible(context, subItem.requiredRoles))
            )
            .map((menuItem, index) => (
              <DesktopMenuItem key={index} {...menuItem} selectedSystem={selectedSystem} logoutUser={logoutUser} />
            ))}
        </ul>
      </nav>
    )}
  </UserPermissionsContext.Consumer>
);

DesktopMenu.propTypes = {
  menuItems: PropTypes.array,
  selectedSystem: PropTypes.object,
  logoutUser: PropTypes.func.isRequired,
};

export default DesktopMenu;
