import { getHistoryParameters, getPoints, getPrecedingPoint } from '@/api';
import { getFirstChartData, getSecondChartData } from '@/utils/localStorage/history';
import moment from 'moment';
import { checkIsFirstChart, formatDate } from './chart-utils';

export const GET_DEVICES_PARAMETERS_REQUEST = 'history/GET_DEVICES_PARAMETERS_REQUEST';
export const GET_DEVICES_PARAMETERS_RESPONSE = 'history/GET_DEVICES_PARAMETERS_RESPONSE';
export const SET_CACHED_PARAMETERS_RESPONSE = 'history/SET_CACHED_PARAMETERS_RESPONSE';
export const SET_CACHED_PARAMETERS_RESPONSE_FOR_SECOND_CHART =
  'history/SET_CACHED_PARAMETERS_RESPONSE_FOR_SECOND_CHART';
export const SET_LOADING_CHART = 'history/SET_LOADING_CHART';
export const SET_PRELOADING_CHART = 'history/SET_PRELOADING_CHART';
export const GET_POINTS_FOR_PARAMETER_RESPONSE = 'history/GET_POINTS_FOR_PARAMETER_RESPONSE';
export const GET_POINTS_FOR_PARAMETER_RESPONSE_FAILED = 'history/GET_POINTS_FOR_PARAMETER_RESPONSE_FAILED';
export const GET_POINTS_FOR_PARAMETER_RESPONSE_FOR_SECOND_CHART =
  'history/GET_POINTS_FOR_PARAMETER_RESPONSE_FOR_SECOND_CHART';
export const GET_POINTS_FOR_PARAMETER_RESPONSE_FAILED_FOR_SECOND_CHART =
  'history/GET_POINTS_FOR_PARAMETER_RESPONSE_FAILED_FOR_SECOND_CHART';
export const DELETE_CHART = 'history/DELETE_CHART';
export const SET_DATE_RANGE = 'history/SET_DATE_RANGE';
export const DELETE_CHART_FOR_SECOND_CHART = 'history/DELETE_CHART_FOR_SECOND_CHART';
export const SET_DATE_RANGE_FOR_SECOND_CHART = 'history/SET_DATE_RANGE_FOR_SECOND_CHART';
export const FULL_SCREEN = 'history/FULL_SCREEN';
export const CLEAN_SYSTEM_HISTORY_CHARTS = 'history/CLEAN_SYSTEM_HISTORY_CHARTS';

export const DateRange = { Year: 'Year', Month: 'Month', Week: 'Week', Day: 'Day', Custom: 'Custom' };

export const findUpdatedChartData = (charts, parameters) => {
  const chartsAfterChangedLanguage = [];
  charts.charts.map((chart) => {
    parameters.find((p) => {
      const matchedParam = p.parameters.find((param) => chart.parameterId === param.parameterId);
      if (matchedParam && matchedParam.parameterId) {
        chartsAfterChangedLanguage.push({
          ...chart,
          parameterName: matchedParam.parameterName,
          category: matchedParam.category,
          parameter: matchedParam.parameterName,
        });
        return matchedParam;
      }
    });
  });
  return chartsAfterChangedLanguage;
};

export const getDevicesAndParameters = (systemId) => async (dispatch) => {
  dispatch({ type: GET_DEVICES_PARAMETERS_REQUEST });

  const parameters = await getHistoryParameters(systemId);
  const cachedFirstChartData = getFirstChartData();
  const cachedSecondChartData = getSecondChartData();

  const paramDeviceId = parameters?.[0]?.parameters?.[0]?.deviceId;
  const isFirstChartDataCached =
    cachedFirstChartData && cachedFirstChartData.charts.length && cachedFirstChartData.charts[0].deviceId;
  const isSecondChartDataCached =
    cachedSecondChartData && cachedSecondChartData.charts.length && cachedSecondChartData.charts[0].deviceId;
  if (paramDeviceId === isFirstChartDataCached) {
    dispatch({
      type: SET_CACHED_PARAMETERS_RESPONSE,
      data: parameters,
      chart: findUpdatedChartData(cachedFirstChartData, parameters),
      dateRange: cachedFirstChartData.range,
      dateFrom: moment(cachedFirstChartData.dateFrom),
      dateTo: moment(cachedFirstChartData.dateTo),
    });
  }
  if (paramDeviceId === isSecondChartDataCached) {
    dispatch({
      type: SET_CACHED_PARAMETERS_RESPONSE_FOR_SECOND_CHART,
      data: parameters,
      chart: findUpdatedChartData(cachedSecondChartData, parameters),
      dateRange: cachedSecondChartData.range,
      dateFrom: moment(cachedSecondChartData.dateFrom),
      dateTo: moment(cachedSecondChartData.dateTo),
    });
  } else {
    dispatch({ type: GET_DEVICES_PARAMETERS_RESPONSE, data: parameters });
  }
};

export const setLoadingChart = (chartNumber) => (dispatch) => dispatch({ type: SET_LOADING_CHART, chartNumber });
export const setPreLoadingChart = (chartNumber) => (dispatch) => dispatch({ type: SET_PRELOADING_CHART, chartNumber });

export const addChart =
  ({
    deviceId,
    parameterId,
    parameter,
    unit,
    dateFrom,
    dateTo,
    aggregationMethod,
    category,
    chartType,
    numberOfChart,
  }) =>
  async (dispatch) => {
    try {
      dispatch(setLoadingChart(numberOfChart));

      const points = await getPoints(
        deviceId,
        parameterId,
        'none',
        aggregationMethod || 'average',
        formatDate(dateFrom),
        formatDate(dateTo)
      );

      let precedingPoint;

      if (points.length > 0) {
        const [firstPoint] = points;
        const [lastPoint] = [...points].reverse();

        precedingPoint = (await getPrecedingPoint(deviceId, parameterId, formatDate(dateFrom))) || {
          ...firstPoint,
          timestamp: moment(dateFrom).utc().subtract(10, 'days').format('YYYY-MM-DDTHH:mm:ssZ'),
        };

        points.push({ ...lastPoint, timestamp: new Date() });
      }

      const isFirstChart = checkIsFirstChart(numberOfChart);
      isFirstChart
        ? dispatch({
            type: GET_POINTS_FOR_PARAMETER_RESPONSE,
            deviceId,
            parameterId,
            parameter,
            unit,
            points,
            precedingPoint,
            category,
            chartType,
          })
        : dispatch({
            type: GET_POINTS_FOR_PARAMETER_RESPONSE_FOR_SECOND_CHART,
            deviceId,
            parameterId,
            parameter,
            unit,
            points,
            precedingPoint,
            category,
            chartType,
          });
    } catch (err) {
      console.error(err);
    }
  };

export const deleteChart = (deviceId, parameterId, numberOfChart) =>
  checkIsFirstChart(numberOfChart)
    ? { type: DELETE_CHART, deviceId, parameterId }
    : { type: DELETE_CHART_FOR_SECOND_CHART, deviceId, parameterId };

export const updateChart =
  ({ dateFrom, dateTo, numberOfChart }) =>
  (dispatch, getState) => {
    const isFirstChart = checkIsFirstChart(numberOfChart);

    if (isFirstChart) {
      const firstCharts = getState().systemHistoryTab?.charts || [];

      if (firstCharts.length === 0) return dispatch(setPreLoadingChart(''));

      firstCharts.forEach((chart) => {
        dispatch(deleteChart(chart.deviceId, chart.parameterId, numberOfChart));
        dispatch(addChart({ ...chart, dateFrom, dateTo, numberOfChart }));
      });

      return;
    }

    const secondCharts = getState().systemHistoryTab?.secondChart || [];

    if (secondCharts.length === 0) return dispatch(setPreLoadingChart(''));

    secondCharts.forEach((chart) => {
      dispatch(deleteChart(chart.deviceId, chart.parameterId, numberOfChart));
      dispatch(addChart({ ...chart, dateFrom, dateTo, numberOfChart }));
    });
  };

export const setDateRange =
  ({ dateRange, dateFrom, dateTo, numberOfChart }) =>
  (dispatch) => {
    const isFirstChart = checkIsFirstChart(numberOfChart);
    const payload = {
      type: SET_DATE_RANGE,
      dateRange,
      dateFrom,
      dateTo,
    };

    dispatch(setPreLoadingChart(numberOfChart));

    if (isFirstChart) {
      return dispatch(payload);
    }

    dispatch({ ...payload, type: SET_DATE_RANGE_FOR_SECOND_CHART });
  };

export const toggleFullScreen = (value) => ({ type: FULL_SCREEN, value });

export const cleanSystemHistoryCharts = () => (dispatch) => dispatch({ type: CLEAN_SYSTEM_HISTORY_CHARTS });
