import React from 'react';
import { NotificationManager } from 'react-notifications';

import * as api from '@/api';
import events, { eventEmitter } from '@/events';
import { getAuthTokens } from '@/utils/localStorage/authentication';
import { FormattedMessage } from 'react-intl';

export const GET_INVITATIONS_REQUEST = 'invitations/GET_INVITATIONS_REQUEST';
export const GET_INVITATIONS_RESPONSE = 'invitations/GET_INVITATIONS_RESPONSE';

export const getServicePartnerInvitations = (spId) => async (dispatch) => {
  dispatch({ type: GET_INVITATIONS_REQUEST });

  const res = await api.getServicePartnerInfo(spId);
  const response = await api.getServicePartnerInvitations();
  if (response.status === 200) {
    dispatch({ type: GET_INVITATIONS_RESPONSE, invitations: response.data, selectedSP: res.data });
  }
};

export const DELETE_INVITATION_REQUEST = 'invitations/DELETE_INVITATION_REQUEST';
export const DELETE_INVITATION_RESPONSE = 'invitations/DELETE_INVITATION_RESPONSE';

export const deleteServicePartnerInvitation = (group, invitation) => async (dispatch) => {
  dispatch({ type: DELETE_INVITATION_REQUEST });

  const token = getAuthTokens();
  const response = await api.deleteServicePartnerInvitation(invitation, token.access_token);
  if (response.status === 200) {
    dispatch({ type: DELETE_INVITATION_RESPONSE, invitation });
  }
};

export const RESEND_INVITATION_REQUEST = 'invitations/RESEND_INVITATION_REQUEST';
export const RESEND_INVITATION_RESPONSE = 'invitations/RESEND_INVITATION_RESPONSE';
export const RESEND_INVITATION_ERROR = 'invitations/RESEND_INVITATION_ERROR';

const UserStatusAtSystem = {
  NonRegistered: 0,
  Registered: 1,
};

export const resendServicePartnerInvitation = (invitation) => async (dispatch) => {
  dispatch({ type: RESEND_INVITATION_REQUEST });
  try {
    const token = getAuthTokens();
    const response = await api.resendServicePartnerInvitation(invitation, token.access_token);
    if (response.status === 200) {
      if (response.data === UserStatusAtSystem.NonRegistered)
        NotificationManager.success(
          <FormattedMessage
            id="add-invitation.error.request.email-not-found"
            defaultMessage="This user does not exist. We have sent an email for registration. After registration, please re-send an invitation to request access to the system"
          />,
          null,
          10000
        );
      dispatch({ type: RESEND_INVITATION_RESPONSE });
    }
  } catch (e) {
    NotificationManager.error(
      <FormattedMessage
        id="invitation.somethingWentWrong"
        defaultMessage="Something went wrong! Please try again later."
      />
    );
    dispatch({ type: RESEND_INVITATION_ERROR });
    eventEmitter.emit(events.appInsights.logError, e);
  }
};
