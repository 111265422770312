export const EL_PRICE_PARAM_ID = '-32002';

export const phone = {
  regex: /^(\+)?[\d\-)(]+$/,
  minLength: 5,
  maxLength: 25,
};

export const address = {
  postalCode: {
    regex: /^[a-zA-Z\d\- ]+$/,
    maxLength: 20,
  },
  cityMaxLength: 100,
  regionMaxLength: 100,
  pattern: /^[\p{L}\p{Nd}'"\-–.,#&/() ]+$/u,
};

export const servicePartner = {
  name: {
    pattern: /^[\p{L}\p{Nd}'\-. ]+$/u,
    maxLength: 50,
  },
};

export const emailAddress = {
  maxLength: 255,
  antiXssRegex: /^[^%<>^$'`*|{}="]+$/,
};

export const timeZoneOffsetRegex = /\(([^)]+)\)/;
export const userNamePattern = /^[\p{L}\p{Nd}'\-. ]+$/u;
export const parameterInvalidValue = -32760;
export const defaultMaxLength = 255;
export const passwordMaxLength = 128;
export const dateFormatForVaction = 'YYYY/MM/DD';

export const PERSIST = 'PERSIST';

export const BRANDS_WITH_WEBSHOP_ACTIVE = ['NIBE'];

// TODO: Rename to brand approval statuses
export const BRAND_APPROVAL_STATUSES = {
  APPROVED: 'Approved',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
};

export const DEFAULT_LANGUAGE = 'en';
export const CLOUD_STATUS_CHECK_INTERVAL = 5 * 60 * 1000;

export const AUTH_REQUEST_HEADER_SCOPE_DEFAULT = 'READSYSTEM REGISTRATION WRITESYSTEM';
export const SP_SELECTED_STORAGE_ID = 'LAST_SELECTED_SERVICE_PARTNER';
export const AUTH_ACCESS_TOKEN = 'AUTH_ACCESS_TOKEN';
export const CONSUMER_TOKEN = 'CONSUMER_TOKEN';
export const OFFLINE_ACCESS = 'offline_access';

export const BRANDS_WITH_FREE_SERVICES = ['CTC'];

export const HIDE_GROUPING_BUTTON_FROM_BRANDS = ['NIBE', 'NIBEF'];

export const GroupType = {
  ServicePartner: 'spGroup',
  System: 'group',
};

export const isTopLevelGroup = {
  Yes: '1',
  No: '0',
};

export const DialogModes = {
  Default: 'default',
  Create: 'create',
  Edit: 'edit',
  Delete: 'delete',
};

export const noRegion = { name: 'None', code: 'NONE' };
