import events, { eventEmitter } from '@/events';
import React, { Component } from 'react';

// TODO: Setup fallback page UI
const FallbackPage = () => (
  <div
    style={{
      paddingTop: '30vh',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      margin: 'auto',
      width: '65%',
    }}
  >
    <h1>Ooops! Something went wrong...</h1>
    <h3>
      Please try <a href={window.location.href}>reloading</a> the page or <a href="/contact">contact</a> support.
    </h3>
  </div>
);

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    eventEmitter.emit(events.appInsights.logError, { error, info });
  }

  render() {
    if (this.state.hasError) {
      return <FallbackPage />;
    }

    // eslint-disable-next-line react/prop-types
    return this.props.children;
  }
}

export default ErrorBoundary;
