import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button, Tooltip } from 'jpi-cloud-web-ui-kit';

import { Link } from '@/components/Link';
import { emitClarityTrackEvent } from '@/components/tracking/Clarity';

import UserPermissionsContext from '../../../UserPermissionsContext';
import StoreLink from '../../StoreLink';

import { menuItemIds, menuItemIdToEventName } from '../../../constants';
import { isMenuLinkDisabled } from '../../../utils';

import '../desktop-menu.scss';

const DesktopSubMenu = ({ subMenuItems, selectedSystem, logoutUser, align }) => {
  const context = useContext(UserPermissionsContext);

  const filteredItems = subMenuItems.filter((item) => !item.hidden && item.visible(context, item.requiredRoles));

  return (
    <div className={'desktopSubMenu' + (align === 'right' ? ' desktopSubMenu--expand-left' : '')}>
      <div className="desktopSubMenu__list">
        {filteredItems.map((menuItem) => {
          if (menuItem.id === menuItemIds.LogOut) {
            return (
              <Button key={menuItem.id} variant="text" className="desktopSubMenu__link" onClick={logoutUser}>
                <FormattedMessage id={menuItem.id} defaultMessage={menuItem.name} />
              </Button>
            );
          }

          const hasBrands = context.brands.length > 0;

          const { disabledDueToBrands, disabledDueToPremium } = isMenuLinkDisabled({
            menuItem,
            userPermissions: context.userPermissions,
            brands: context.brands,
          });

          if (menuItem.id === menuItemIds.SmartHomeDevices) {
            return (
              <StoreLink
                key={menuItem.id}
                menuItem={menuItem}
                disabledDueToBrands={disabledDueToBrands}
                redirectTokenFunc={context.redirectTokenFunc}
                selectedSystem={selectedSystem}
              />
            );
          }

          const disabled = disabledDueToBrands || disabledDueToPremium;
          const path = disabled ? '' : menuItem.path(context.selectedSystemId);

          const tooltipContent = disabledDueToBrands ? (
            hasBrands ? (
              <FormattedMessage
                id={'menu.brand.notapproved.message'}
                defaultMessage="One of your selected brands is awaiting appromenuItem."
              />
            ) : (
              <FormattedMessage
                id={'menu.brand.notexist.message'}
                defaultMessage="You are not connected to any brand."
              />
            )
          ) : disabledDueToPremium ? (
            <FormattedMessage
              id={'premiumFeatures.subscribe'}
              defaultMessage="You need to subscribe to the premium functionality to be able to change settings."
            />
          ) : null;

          return (
            <Tooltip key={menuItem.id} overlay={tooltipContent} position="right">
              <Link
                to={path}
                aria-label={disabled ? `Disabled Link - ${menuItem.name}` : null}
                className={disabled ? 'desktopSubMenu__link-disabled' : 'desktopSubMenu__link'}
                preNavigationCallback={() => emitClarityTrackEvent(menuItemIdToEventName[menuItem.id])}
              >
                <FormattedMessage id={menuItem.id} defaultMessage={menuItem.name} />
              </Link>
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
};

DesktopSubMenu.propTypes = {
  subMenuItems: PropTypes.array,
  align: PropTypes.string,
  selectedSystemId: PropTypes.string,
  selectedSystem: PropTypes.object,
  logoutUser: PropTypes.func.isRequired,
};

export default DesktopSubMenu;
