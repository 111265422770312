import {
  CREATE_USER_REQUEST,
  CREATE_USER_RESPONSE,
  CREATE_USER_RESPONSE_ERROR,
  CHECK_USER_EMAIL_REQUEST,
  VALIDATE_OLD_USER_CREDENTIALS_REQUEST,
  VALIDATE_OLD_USER_CREDENTIALS_RESPONSE_SUCCEEDED,
  VALIDATE_OLD_USER_CREDENTIALS_RESPONSE_FAILED,
  MIGRATE_OLD_USER_REQUEST,
  MIGRATE_OLD_USER_RESPONSE_SUCCEEDED,
  MIGRATE_OLD_USER_RESPONSE_FAILED,
} from './actions';
import { VALIDATE_CREDENTIALS_RESPONSE_FAILED } from '../Login/actions';

const initialState = {
  user: null,
  loading: false,
  migrateUserError: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER_REQUEST:
      return { ...state, loading: true };
    case CREATE_USER_RESPONSE:
      return { ...state, loading: false, user: action.user };
    case CREATE_USER_RESPONSE_ERROR:
      return { ...state, error: action.error };
    case CHECK_USER_EMAIL_REQUEST:
      return { ...state, loading: true, migrateUserError: null, error: null };
    case VALIDATE_OLD_USER_CREDENTIALS_REQUEST:
      return { ...state, loading: true, migrateUserError: null };
    case VALIDATE_OLD_USER_CREDENTIALS_RESPONSE_SUCCEEDED:
      return { ...state, loading: false, oldUserId: action.data && action.data.id };
    case VALIDATE_OLD_USER_CREDENTIALS_RESPONSE_FAILED:
      return { ...state, loading: false, migrateUserError: action.error };
    case MIGRATE_OLD_USER_REQUEST:
      return { ...state, loading: true };
    case MIGRATE_OLD_USER_RESPONSE_FAILED:
      return { ...state, loading: false, migrateUserError: action.error };
    case MIGRATE_OLD_USER_RESPONSE_SUCCEEDED:
      return { ...state, loading: false, migrateUserError: null, oldUserId: null };
    case VALIDATE_CREDENTIALS_RESPONSE_FAILED:
      return { ...state, user: { ...state.user, id: action.error.Id } };
    default:
      return state;
  }
};
