import {
  BRANDS_WITH_NOEM_FIRMWARE,
  ELIGIBLE_BRANDS_FOR_HIDING_HISTORY,
} from './components/SystemNotificationsTab/constants';
import { AncestorGroup, FlattenAncestorsType } from './types';

/**
 *
 * function to compare strings which returns a number
 * indicating whether stringOne comes before,
 * or after, or is the same as stringTwo in sort order
 * @param stringOne string
 * @param stringTwo string
 * @returns Number
 */
export const compareNumericStrings = (stringOne, stringTwo) => {
  if ('string' === typeof stringOne && 'function' === typeof stringOne.localeCompare) {
    return stringOne.localeCompare(stringTwo);
  }
  const stringToInteger = (str) => parseInt(str.replace(/\D+/g, ''));
  const valueOne = stringToInteger(stringOne);
  const valueTwo = stringToInteger(stringTwo);
  if (valueOne === valueTwo) {
    return 0;
  }
  return valueOne < valueTwo ? -1 : 1;
};

export const compareVersions = (versionOne, versionTwo) => {
  if (typeof versionOne === 'number' && typeof versionTwo === 'number') {
    if (versionOne === versionTwo) {
      return 0;
    }

    return versionOne < versionTwo ? -1 : 1;
  }

  if (!(typeof versionOne === 'string' && typeof versionTwo === 'string')) {
    return null;
  }
  const oneSplitted = versionOne.split('.');
  const twoSplitted = versionTwo.split('.');
  const len = Math.max(versionOne.length, versionTwo.length);

  for (let i = 0; i < len; i++) {
    if (isFirstPartNewerVersion(oneSplitted[i], twoSplitted[i])) {
      return 1;
    } else if (isFirstPartNewerVersion(twoSplitted[i], oneSplitted[i])) {
      return -1;
    }
  }

  return 0;
};

// @ts-ignore
export const isVersionUpdateAvailable = ({ firmware, ...main } = {}) => {
  const { desiredFwVersion, currentFwVersion, pendingFwVersion = '' } = firmware || main;

  if (desiredFwVersion) return desiredFwVersion !== currentFwVersion;

  if (pendingFwVersion) return pendingFwVersion !== currentFwVersion;

  // NOTE: In case we do not have current and desired(pending) FW versions we can NOT signal update
  return desiredFwVersion !== currentFwVersion;
};

const isFirstPartNewerVersion = (versionPartOne, versionPartTwo) => {
  return (
    (versionPartOne && !versionPartTwo && parseInt(versionPartOne) > 0) ||
    parseInt(versionPartOne) > parseInt(versionPartTwo)
  );
};

const isNoemFirmwareId = (firmwareId) => /^noem-e\d+$/.test(firmwareId.toLowerCase());
const isBrandWithNoemFirmware = (brandId) => BRANDS_WITH_NOEM_FIRMWARE.includes(brandId.toLowerCase());

export const isSystemEligibleForHidingHistory = (selectedSystem) => {
  const brandId = selectedSystem?.brandId;
  const firmwareId = selectedSystem?.firmware?.firmwareId;

  if (!brandId || !firmwareId || (isNoemFirmwareId(firmwareId) && isBrandWithNoemFirmware(brandId))) {
    return false;
  }

  return ELIGIBLE_BRANDS_FOR_HIDING_HISTORY.includes(brandId.toLowerCase());
};

export const flattenAncestors = (topLevelGroup: AncestorGroup): FlattenAncestorsType[] => {
  const result = [];
  const { parentGroup } = topLevelGroup;
  let parent = parentGroup;

  while (parent) {
    const { parentGroup: nextParent, ...group } = parent;
    result.push({ id: group.groupId, name: group.groupName });
    parent = nextParent;
  }

  return result.reverse();
};
