import { cloneDeep } from 'lodash';

import {
  GET_SYSTEMS_REQUEST,
  GET_SYSTEMS_RESPONSE,
  GET_NEXT_SYSTEMS_RESPONSE,
  GET_SYSTEMS_GEOPOSITION_REQUEST,
  GET_SYSTEMS_GEOPOSITION_RESPONSE,
  GET_TOP_LEVEL_GROUPS_RESPONSE,
  GET_TOP_LEVEL_GROUPS_REQUEST,
  GET_SUB_GROUPS_AND_SYSTEMS_REQUEST,
  GET_SUB_GROUPS_AND_SYSTEMS_RESPONSE,
  GET_SUB_GROUPS_AND_SYSTEMS_ERROR,
  SET_GROUPS_AND_SYSTEMS,
  DELETE_GROUP_RESPONSE,
  UPDATE_GROUP_NAME_RESPONSE,
} from './actions';

import {
  ADD_SYSTEM_TAG_RESPONSE,
  DELETE_SYSTEM_TAG_RESPONSE,
  DISCONNECT_SYSTEM_RESPONSE,
} from '../SystemSettings/components/SystemStatusTab/actions';
import { findGroupById } from './components/Grouping/utils';

import { LOGOUT_USER } from '../Login/actions';

const initialState = {
  systems: [],
  systemsTotal: 0,
  systemsLoaded: false,
  geopositionsLoaded: false,
  topLevelGroups: [],
  groups: [],
  loadingGroups: false,
};

const deleteTag = (systems, result) => {
  const newSystems = systems.map((s) =>
    s.id === result.groupId ? { ...s, tags: s.tags.filter((a) => a !== result.tag) } : s
  );
  return newSystems;
};

const addNewTag = (systems, result) => {
  const newSystems = systems.map((s) => (s.id === result.groupId ? { ...s, tags: [...s.tags, result.tag] } : s));
  return newSystems;
};

const mergeGroupsAndSystems = (groups, newGroupsAndSystems, groupId) => {
  if (groupId) {
    if (groups && groups.length > 0) {
      groups.map((group, index) => {
        if (group.id === groupId) {
          groups[index].childGroups = newGroupsAndSystems;
        } else if (groups[index].childGroups && groups[index].childGroups.length > 0) {
          groups[index].childGroups = mergeGroupsAndSystems(group.childGroups, newGroupsAndSystems, groupId);
        }
      });
      return groups;
    }
  }
  return newGroupsAndSystems;
};

const removeGroupById = (groups, groupId) => {
  findGroupById(groups, groupId, (parentSet, index) => {
    parentSet.splice(index, 1); // delete
  });
  return groups;
};

const updateGroupById = (groups, { id, name }) => {
  const groupToEdit = findGroupById(groups, id);
  groupToEdit.name = name;
  return groups;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SYSTEMS_REQUEST:
      return initialState;
    case GET_SYSTEMS_RESPONSE:
      return {
        ...state,
        systems: action.payload.systems,
        systemsTotal: action.payload.systemsTotal,
        systemsLoaded: true,
        geopositionsLoaded: false,
        systemsStatus: action.payload.systemsStatus,
        notificationsCount: action.payload.notificationsCount,
      };
    case GET_NEXT_SYSTEMS_RESPONSE:
      return { ...state, systems: [...state.systems, ...action.systems] };
    case GET_SYSTEMS_GEOPOSITION_REQUEST:
      return { ...state, geopositionsLoaded: false };
    case GET_SYSTEMS_GEOPOSITION_RESPONSE:
      return { ...state, systems: action.systemsWithGeoposition, geopositionsLoaded: true };
    case ADD_SYSTEM_TAG_RESPONSE:
      return { ...state, systems: addNewTag(state.systems, action.result) };
    case DELETE_SYSTEM_TAG_RESPONSE:
      return { ...state, systems: deleteTag(state.systems, action.result) };
    case DISCONNECT_SYSTEM_RESPONSE:
      return {
        ...state,
        systems: state.systems.filter((system) => system.id !== action.system.id),
        systemsTotal: state.systemsTotal - 1,
      };
    case GET_TOP_LEVEL_GROUPS_RESPONSE:
      return { ...state, groups: action.topLevelGroups, systemsLoaded: true };
    case GET_TOP_LEVEL_GROUPS_REQUEST:
      return { ...state, systemsLoaded: false };
    case LOGOUT_USER:
      return initialState;
    case GET_SUB_GROUPS_AND_SYSTEMS_REQUEST:
      return { ...state, loadingGroups: true };
    case GET_SUB_GROUPS_AND_SYSTEMS_RESPONSE:
    case GET_SUB_GROUPS_AND_SYSTEMS_ERROR:
      return { ...state, loadingGroups: false };
    case SET_GROUPS_AND_SYSTEMS:
      return {
        ...state,
        groups: mergeGroupsAndSystems(cloneDeep(state.groups), action.groupsAndSystems, action.groupId),
        loadingGroups: false,
      };
    case DELETE_GROUP_RESPONSE:
      return { ...state, groups: removeGroupById(cloneDeep(state.groups), action.groupId) };
    case UPDATE_GROUP_NAME_RESPONSE:
      return { ...state, groups: updateGroupById(cloneDeep(state.groups), { id: action.id, name: action.name }) };
    default:
      return state;
  }
};
