import { Brands } from '@/assets/brands/brands';
import mainTheme from '../theme';

const theme = {
  ...mainTheme,
  name: Brands.DANDELION,
  COLORS: {
    ...mainTheme.COLORS,
    primary: '#f7d748',
    secondary: '#25324d',
    text: {
      ...mainTheme.COLORS.text,
      primaryContrast: '#414042',
    },
  },
};

export default theme;
