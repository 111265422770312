import React, { lazy, useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { BRAND_APPROVAL_STATUSES } from '@/components/constants';

import SignedInLayout from '@/components/layout/SignInLayout';

const NotAllowed = lazy(() => import(/* webpackChunkName: "NotAllowed" */ '@/pages/NotAllowed'));

export function PermissionsGuard({ children, userInfo, servicePartner, subscribedUser = false, requiredRoles = [] }) {
  const history = useHistory();
  const [preventAccess, setPreventAccess] = useState(false);

  const subsPayment = userInfo?.recurringPayment;

  const userHasOrHadSubscriptions = () => subsPayment?.subscription !== null;
  const userHasValidPaidSubscription = () => subsPayment?.isValid && !subsPayment?.isPaused;
  const userHasPermissions = () => requiredRoles.some((role) => userInfo?.spRoles.includes(role));

  const spHasBrandApprovals = () =>
    !servicePartner?.brands?.some((brand) => brand.approvalStatus !== BRAND_APPROVAL_STATUSES.APPROVED);

  const applyRestrictions = () => {
    // TODO: Fire INFO notification that user has to get brand approval first
    if (!spHasBrandApprovals()) setPreventAccess(true);

    // TODO: Fire INFO notification that user has to activate trial first
    if (!userHasOrHadSubscriptions()) {
      if (history.location.pathname === '/redeem-voucher') setPreventAccess(true);
    }

    // TODO: Fire INFO notification that user has to buy subscriptions first
    if (subscribedUser && !userHasValidPaidSubscription()) {
      if (history.location.pathname === '/systems') return history.push('/no-subscription');

      setPreventAccess(true);
    }

    // TODO: Fire INFO notification that user has to get permissions first
    if (!userHasPermissions()) setPreventAccess(true);
  };

  useEffect(() => {
    applyRestrictions();
  }, [userInfo, servicePartner]);

  return (
    <>
      {preventAccess ? (
        <SignedInLayout hideHero={false}>
          <NotAllowed />
        </SignedInLayout>
      ) : (
        children
      )}
    </>
  );
}

const stateProps = ({ app: { userInfo, servicePartner } }) => ({
  userInfo,
  servicePartner,
});

export default connect(stateProps)(PermissionsGuard);
