import * as api from '@/api';
// TODO: Move to constants
import events, { eventEmitter } from '@/events';
import { ALARM_TYPES } from '@/pages/SystemSettings/components/SystemNotificationsTab/constants';

export const GET_NOTIFICATIONS_REQUEST = 'alarms/GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_RESPONSE_SUCCEEDED = 'alarms/GET_NOTIFICATIONS_RESPONSE_SUCCEEDED';
export const getNotifications = () => async (dispatch) => {
  dispatch({ type: GET_NOTIFICATIONS_REQUEST });

  const response = await api.getAlarms(ALARM_TYPES.ACTIVE);
  if (response.status === 200) {
    dispatch({ type: GET_NOTIFICATIONS_RESPONSE_SUCCEEDED, notifications: response.data });
  }
};

export const GET_IOT_STORE_REDIRECT_URL_REQUEST = 'store/GET_IOT_STORE_REDIRECT_URL_REQUEST';

export const getIotStoreRedirectUrl = () => async (dispatch) => {
  dispatch({ type: GET_IOT_STORE_REDIRECT_URL_REQUEST });
  try {
    const windowReference = window.open();
    const response = await api.getIotStoreRedirectUrl();
    if (response.status === 200) {
      windowReference.location = response.data.iotStoreUrl;
    }
  } catch (err) {
    eventEmitter.emit(events.appInsights.logError, err);
  }
};
