import * as api from '@/api';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NotificationManager } from 'react-notifications';
import { ALARM_TYPES, activateAidModeErrorMessages, resetAlarmErrorMessages, unknownErrorMessage } from '../constants';
import { ActiveAidMode } from '../types';

export const GET_ALARMS_REQUEST = 'systemNotificationsTab/GET_ALARMS_REQUEST';
export const GET_ALARMS_RESPONSE_SUCCEEDED = 'systemNotificationsTab/GET_ALARMS_RESPONSE_SUCCEEDED';

export const getInboxAlarms = (params) => async (dispatch) => {
  const { deviceId, page = 1, pageSize = 10 } = params;

  dispatch({ type: GET_ALARMS_REQUEST, deviceId, alarmsType: 'inbox' });

  const response = await api.getAlarms({ deviceId, type: ALARM_TYPES.ACTIVE, page, pageSize });

  const devices = Object.keys(_.groupBy(response.data.page, 'deviceId'));

  const aidModeResponses = await Promise.all(devices.map((deviceId) => api.getActiveAidMode(deviceId)));
  const devicesInAidMode = aidModeResponses.map((el, i) => ({
    deviceId: devices[i],
    aidMode: el.status === 200 ? el.data.aidMode : 'off',
  }));

  const alarms = response.data.page
    .map((alarm) =>
      devicesInAidMode.find((el) => el.deviceId == alarm.deviceId && el.aidMode === 'on')
        ? { ...alarm, activeAidMode: ActiveAidMode.ON }
        : { ...alarm, activeAidMode: ActiveAidMode.OFF }
    )
    .sort((a, b) => (a.datetime < b.datetime ? 1 : -1));

  dispatch({
    type: GET_ALARMS_RESPONSE_SUCCEEDED,
    deviceId,
    alarmsType: 'inbox',
    alarms,
    page,
    totalCount: response.data.totalCount,
  });
};

export const getArchivedAlarms = (params) => async (dispatch) => {
  const { deviceId, page = 1, pageSize = 10 } = params;

  dispatch({ type: GET_ALARMS_REQUEST, deviceId, alarmsType: 'archived' });

  const response = await api.getArchivedAlarms({ deviceId, page, pageSize });

  const alarms = response.data.page.sort((a, b) => (a.datetime < b.datetime ? 1 : -1));

  dispatch({
    type: GET_ALARMS_RESPONSE_SUCCEEDED,
    deviceId,
    alarmsType: 'archived',
    alarms,
    page,
    totalCount: response.data.totalCount,
  });
};

export const RESET_ALARM_REQUEST = 'notification/RESET_ALARM_REQUEST';
export const RESET_ALARM_RESPONSE_SUCCEEDED = 'notification/RESET_ALARM_RESPONSE_SUCCEEDED';
export const RESET_ALARM_RESPONSE_FAILED = 'notification/RESET_ALARM_RESPONSE_FAILED';
export const resetAlarm = (alarm) => async (dispatch) => {
  dispatch({ type: RESET_ALARM_REQUEST, alarm });

  try {
    await api.resetAlarm(alarm);
    dispatch({ type: RESET_ALARM_RESPONSE_SUCCEEDED, alarm });
  } catch (e) {
    const errorDetails = resetAlarmErrorMessages[e.response?.status] || unknownErrorMessage;
    NotificationManager.error(<FormattedMessage id={errorDetails.id} defaultMessage={errorDetails.defaultMessage} />);

    dispatch({
      type: RESET_ALARM_RESPONSE_FAILED,
      alarm,
    });
  }
};

export const ACTIVATE_AID_MODE_ALARM_REQUEST = 'alarm/ACTIVATE_AID_MODE_ALARM_REQUEST';
export const ACTIVATE_AID_MODE_ALARM_RESPONSE_SUCCEEDED = 'alarm/ACTIVATE_AID_MODE_ALARM_RESPONSE_SUCCEEDED';
export const ACTIVATE_AID_MODE_ALARM_RESPONSE_FAILED = 'alarm/ACTIVATE_AID_MODE_ALARM_RESPONSE_FAILED';
export const activateAidModeForAlarm = (alarm) => async (dispatch) => {
  const aidMode = alarm.activeAidMode === ActiveAidMode.ON ? ActiveAidMode.OFF : ActiveAidMode.ON;
  dispatch({ type: ACTIVATE_AID_MODE_ALARM_REQUEST, alarm, aidMode });

  try {
    await api.activateAidModeForAlarm(alarm, aidMode);
    dispatch({ type: ACTIVATE_AID_MODE_ALARM_RESPONSE_SUCCEEDED, request: { alarm, aidMode } });
  } catch (e) {
    const errorDetails = activateAidModeErrorMessages[e.response?.status] ?? unknownErrorMessage;
    NotificationManager.error(<FormattedMessage id={errorDetails.id} defaultMessage={errorDetails.defaultMessage} />);

    dispatch({
      type: ACTIVATE_AID_MODE_ALARM_RESPONSE_FAILED,
      request: { alarm, aidMode },
    });
  }
};

export const SELECT_ALARM = 'alarm/SELECT_ALARM';
export const selectAlarm = (alarm) => async (dispatch) => {
  dispatch({ type: SELECT_ALARM, alarm });
  await getAlarmCausesAction(alarm.deviceId, alarm.id)(dispatch);
};

export const DESELECT_ALARM = 'alarm/DESELECT_ALARM';
export const deselectAlarm = () => (dispatch) => {
  dispatch({ type: DESELECT_ALARM });
};

export const GET_ALARM_CAUSES_REQUEST = 'alarm/GET_ALARM_CAUSES_REQUEST';
export const GET_ALARM_CAUSES_RESPONSE_SUCCEEDED = 'alarm/GET_ALARM_CAUSES_RESPONSE_SUCCEEDED';
export const getAlarmCausesAction = (deviceId, alertId) => async (dispatch) => {
  dispatch({ type: GET_ALARM_CAUSES_REQUEST });
  try {
    const response = await api.getAlarmCauses(deviceId, alertId);
    dispatch({ type: GET_ALARM_CAUSES_RESPONSE_SUCCEEDED, alarmCauses: response.data });
  } catch (e) {
    dispatch({ type: GET_ALARM_CAUSES_RESPONSE_SUCCEEDED, alarmCauses: [] });
  }
};
