import { DELETE_INVITATION_RESPONSE, GET_INVITATIONS_REQUEST, GET_INVITATIONS_RESPONSE } from './actions';

import { LOGOUT_USER } from '../Login/actions';

const initialState = {
  loaded: false,
  loading: false,
  invitations: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_INVITATIONS_REQUEST:
      return { ...state, invitations: [], loaded: false, loading: true };
    case GET_INVITATIONS_RESPONSE:
      return {
        ...state,
        invitations: action.invitations.filter((s) => s.status === 'Invited'),
        selectedSP: action.selectedSP,
        loading: false,
        loaded: true,
      };
    case DELETE_INVITATION_RESPONSE:
      return { ...state, invitations: state.invitations.filter((s) => s.id !== action.invitation.id) };
    case LOGOUT_USER:
      return { ...state, invitations: [], loaded: false, loading: false };
    default:
      return state;
  }
};
