const initialState = {
  shopId: null,
  products: {
    items: [],
    loaded: false,
    loading: false,
    error: null,
    hasFreeServiceError: false,
  },
  cart: {
    id: null,
    lineItems: [],
    address: null,
    discount: null,
    error: null,
    init: false,
    loading: false,
  },
  order: {
    error: null,
    loading: false,
    data: null,
  },
  orders: {
    init: false,
    loading: false,
    error: null,
    data: [],
  },
  vatDetails: {
    init: false,
    loading: false,
    error: null,
    valid: true,
  },
  paymentProviders: {
    init: false,
    loading: false,
    error: null,
    data: [],
  },
  nextBillingDate: {
    init: false,
    loaded: false,
    error: null,
    data: {
      price: 0,
      quantity: 0,
    },
  },
  trialSubscription: {
    init: false,
    loading: false,
    failed: false,
  },
  stripeSpecificPaidBrands: {
    error: null,
    loading: false,
    data: ['clmtest', 'climatemaster'], // TODO: Check
  },
  paidBrands: [],
};

export default initialState;
