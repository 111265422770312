import React, { ReactElement, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

function UnAuthGuard({ children, userInfo }): ReactElement {
  const { push } = useHistory();

  useEffect(() => {
    if (userInfo) return push('/systems');
  }, [userInfo]);

  return <>{children}</>;
}

export default UnAuthGuard;
