import { Brands } from '@/assets/brands/brands';
import mainTheme from '../theme';

const theme = {
  ...mainTheme,
  name: Brands.AIRSITE,
  COLORS: {
    ...mainTheme.COLORS,
    primary: '#1E4C9C',
    secondary: '#1E4C9C',
  },
};

export default theme;
