import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getDeviceFeatures } from '@/components/FeaturesFlags/actions';
import { menuSystem } from '@/components/FeaturesFlags/menuSystem';
import { sortArrayByLocaleCompare } from '@/utils';

import { selectLanguage } from './actions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './languageselector.scss';

// TODO: Refactor and merge with CustomLanguageSelector
export function LanguageSelector({
  languages = [],
  selectedLanguage,
  selectLanguage,
  userInfo,
  selectedSystem,
  getDeviceFeatures,
  menuType,
}) {
  const changeLanguage = async (e) => {
    e.preventDefault();

    const { value } = e.target;
    const devices = selectedSystem?.devices || [];
    const [mainDevice] = devices;

    await selectLanguage(value, userInfo);

    if (mainDevice?.id && menuType === menuSystem.simplified) {
      getDeviceFeatures(mainDevice.id);
    }
  };

  const sortedLanguageOptions = sortArrayByLocaleCompare(languages, 'displayName', 'asc');

  return (
    <div className="languageSelector">
      <select
        aria-label="language-selector"
        className="languageSelector__selectControl"
        onChange={changeLanguage}
        value={selectedLanguage}
      >
        {sortedLanguageOptions.length > 0 ? (
          sortedLanguageOptions.map(({ id, name, displayName }) => {
            const [lang] = name.split('-');
            return (
              <option key={id} value={lang}>
                {displayName}
              </option>
            );
          })
        ) : (
          <option value="en">English</option>
        )}
      </select>
      <FontAwesomeIcon icon="globe-americas" className="languageSelector__globeIcon" />
    </div>
  );
}

LanguageSelector.propTypes = {
  selectLanguage: PropTypes.func,
  selectedLanguage: PropTypes.string,
  userInfo: PropTypes.object,
  selectedSystem: PropTypes.object,
  getDeviceFeatures: PropTypes.func.isRequired,
  menuType: PropTypes.string.isRequired,
  languages: PropTypes.array,
};

const mapStateToProps = ({
  app: { userInfo, languages, selectedSystem },
  language: { selectedLanguage },
  features: { deviceFeatures: { menuSystem } = {} },
}) => ({
  languages,
  selectedLanguage,
  selectedSystem,
  menuType: menuSystem,
  userInfo,
});

const mapDispatchToProps = { selectLanguage, getDeviceFeatures };

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
