/* eslint-disable import/no-named-as-default */
import React from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDoubleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faCheckCircle,
  faCog,
  faDownload,
  faFire,
  faGlobe,
  faGlobeAmericas,
  faGlobeEurope,
  faPen,
  faPlay,
  faTimesCircle,
  faWind,
} from '@fortawesome/free-solid-svg-icons';

import Notifications from '@/components/layout/Notifications';
import Routes from '@/components/Routes';

import AppInitializer from './AppInitializer';
import FallbackProvider from './FallbackProvider';

import AppInsights from './tracking/AppInsights';
import Clarity from './tracking/Clarity';

import ErrorBoundary from './tracking/ErrorBoundary';

library.add(
  faGlobe,
  faGlobeAmericas,
  faGlobeEurope,
  faPlay,
  faAngleUp,
  faAngleRight,
  faAngleDown,
  faAngleLeft,
  faAngleDoubleUp,
  faAngleDoubleRight,
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faCheckCircle,
  faTimesCircle,
  faFire,
  faCog,
  faWind,
  faDownload,
  faPen,
  faBatteryEmpty,
  faBatteryHalf,
  faBatteryFull
);

const queryClient = new QueryClient();

const App = () => (
  <>
    <AppInsights />
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <Notifications />
        <AppInitializer>
          <Clarity />
          <FallbackProvider>
            <Routes />
          </FallbackProvider>
        </AppInitializer>
      </QueryClientProvider>
    </ErrorBoundary>
  </>
);

App.propTypes = {
  children: PropTypes.element,
};

export default App;
