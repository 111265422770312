import { DEFAULT_BRAND } from '@/assets/brands/brands';
import { theme as defaultTheme } from 'jpi-cloud-web-ui-kit';

const theme = {
  ...defaultTheme,
  name: DEFAULT_BRAND,
  COLORS: {
    ...defaultTheme.COLORS,
    warning: '#FF5722',
    info: '#1A237E',
    defaultBrand: '#1A237E',
    text: {
      ...defaultTheme.COLORS.text,
      muted: '#585858',
      primaryContrast: '#ffffff',
    },
    chart: {
      // ...defaultTheme.COLORS.chart,
      default: '#585858',
      success: '#4bb945',
      error: '#a81524',
    },
    border: {
      main: '#ddd',
    },
    background: {
      ...defaultTheme.COLORS.background,
      table: '#f7f7f7',
    },
    shadow: {
      light: '#0000001A',
      medium: '#0000004D',
    },
  },
};

export default theme;
