import { Brands } from '@/assets/brands/brands';
import mainTheme from '../theme';

const theme = {
  ...mainTheme,
  name: Brands.ENERTECH,
  COLORS: {
    ...mainTheme.COLORS,
    primary: '#FF6B00',
    secondary: '#FF6B00',
  },
};

export default theme;
