import * as api from '@/api';
import { SP_SELECTED_STORAGE_ID } from '@/components/constants';
import { getFromLocalStorageByKey, saveToLocalStorage } from '@/utils/localStorage';

import { logoutUser } from '../Login/actions';
import { splitVatNumber } from './utils';

export const GET_SERVICE_PARTNER_REQUEST = 'editServicePartner/GET_SERVICE_PARTNER_REQUEST';
export const GET_SERVICE_PARTNER_RESPONSE = 'editServicePartner/GET_SERVICE_PARTNER_RESPONSE';

export const getServicePartnerInfo = (spId) => async (dispatch) => {
  dispatch({ type: GET_SERVICE_PARTNER_REQUEST });
  const response = await api.getServicePartnerInfo(spId);
  if (response.status === 200) {
    const servicePartner = splitVatNumber(response.data);

    dispatch({ type: GET_SERVICE_PARTNER_RESPONSE, servicePartner });
  }
};

export const EDIT_SERVICE_PARTNER_REQUEST = 'editServicePartner/EDIT_SERVICE_PARTNER_REQUEST';
export const EDIT_SERVICE_PARTNER_RESPONSE = 'editServicePartner/EDIT_SERVICE_PARTNER_RESPONSE';
export const EDIT_SERVICE_PARTNER_ERROR = 'editServicePartner/EDIT_SERVICE_PARTNER_ERROR';

export const editServicePartnerInfo = (spId, sp) => async (dispatch) => {
  dispatch({ type: EDIT_SERVICE_PARTNER_REQUEST });
  try {
    const vatPrefix = sp.vatPrefix || '';
    const servicePartnerToEdit = {
      ...sp,
      vatNumber: vatPrefix + sp.vatNumber,
    };
    const editResponse = await api.editServicePartnerInfo(servicePartnerToEdit);
    const updatedName = { ...getFromLocalStorageByKey(SP_SELECTED_STORAGE_ID), servicePartnerName: sp.name };
    if (editResponse.status === 204) {
      const response = await api.getServicePartnerInfo(spId);
      saveToLocalStorage(SP_SELECTED_STORAGE_ID, updatedName);
      if (response.status === 200) {
        const servicePartner = splitVatNumber(response.data);
        dispatch({ type: EDIT_SERVICE_PARTNER_RESPONSE, servicePartner });
      } else {
        dispatch({ type: EDIT_SERVICE_PARTNER_ERROR });
      }
      return;
    }
    dispatch({ type: EDIT_SERVICE_PARTNER_ERROR });
  } catch (e) {
    dispatch({ type: EDIT_SERVICE_PARTNER_ERROR });
  }
};

export const DELETE_ACCOUNT_REQUEST = 'profile/DELETE_ACCOUNT';
export const DELETE_ACCOUNT_RESPONSE_SUCCEDED = 'profile/DELETE_ACCOUNT_RESPONSE_SUCCEDED';
export const DELETE_ACCOUNT_FAILED = 'profile/DELETE_ACCOUNT_FAILED';

export const deleteServicePartnerAccount = (spId) => async (dispatch) => {
  dispatch({ type: DELETE_ACCOUNT_REQUEST });
  try {
    await api.deleteLastServicePartnerAccount(spId);
    dispatch({ type: DELETE_ACCOUNT_RESPONSE_SUCCEDED });
    dispatch(logoutUser(false));
    return true;
  } catch (e) {
    dispatch({ type: DELETE_ACCOUNT_FAILED, error: 'password' });
  }

  return false;
};

export const leaveServicePartnerAccount = () => async (dispatch) => {
  dispatch({ type: DELETE_ACCOUNT_REQUEST });
  try {
    await api.leaveServicePartnerAccount();
    dispatch({ type: DELETE_ACCOUNT_RESPONSE_SUCCEDED });
    dispatch(logoutUser(false));
    return true;
  } catch (e) {
    dispatch({ type: DELETE_ACCOUNT_FAILED, error: 'password' });
  }

  return false;
};
export const RESET_DELETE_ERROR = 'profile/RESET_DELETE_ERROR ';
export const resetDeleteError = () => (dispatch) => dispatch({ type: RESET_DELETE_ERROR });
