import { Brands } from '@/assets/brands/brands';
import mainTheme from '../theme';

const theme = {
  ...mainTheme,
  name: Brands.TIKI,
  COLORS: {
    ...mainTheme.COLORS,
    primary: '#2d88a0',
    secondary: '#234a8f',
    text: {
      ...mainTheme.COLORS.text,
    },
  },
};

export default theme;
