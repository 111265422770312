import React, { ReactElement, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import PermissionsGuard from './PermissionsGuard';

import { allRoutes } from '../config';

function AuthGuard({ children, userInfo, ...routeProps }): ReactElement {
  const { push } = useHistory();

  const getRedirection = () => {
    const currentPathValid = allRoutes.some(({ path }) => location?.pathname?.split('/').some((c) => path.match(c)));

    return currentPathValid ? '?redirectTo=' + location?.pathname : '';
  };

  useEffect(() => {
    if (!userInfo) return push(`/login${getRedirection()}`);
  }, [userInfo]);

  return <PermissionsGuard {...routeProps}>{children}</PermissionsGuard>;
}

export default AuthGuard;
