import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import UserPermissionsContext from '@/components/layout/Header/UserPermissionsContext';
import LanguageSelector from '@/components/layout/LanguageSelector';

import HamburgerSubMenuItem from '../HamburgerSubMenuItem';

import { menuItemIds } from '@/components/layout/Header/constants';

import { isMenuLinkDisabled } from '../../../../utils';

import './hamburger-sub-menu.scss';

const HamburgerSubMenu = ({ id, name, children, disabled, selectedSystem, logoutUser }) => {
  if (id === menuItemIds.Language)
    return (
      <div className="hamburgerMenuItem--languageSelector hamburgerMenuItem">
        <div className="hamburgerMenuItem__menuItemList">
          <div className="hamburgerMenuItem__menuItemHeading">
            <FormattedMessage id={id} defaultMessage={name} />
          </div>
          <div className="hamburgerMenuItem__menuItem">
            <LanguageSelector />
          </div>
        </div>
      </div>
    );

  const context = useContext(UserPermissionsContext);

  const filteredItems = children
    .filter((menuItem) => menuItem.visible(context, menuItem.requiredRoles))
    .map((menuItem) => {
      const { disabledDueToPremium, disabledDueToBrands } = isMenuLinkDisabled({
        menuItem: menuItem,
        userPermissions: context.userPermissions,
        brands: context.brands,
      });

      const subItemDisabled = disabledDueToPremium || disabledDueToBrands;

      return {
        ...menuItem,
        disabled: subItemDisabled,
        path: disabled ? '' : menuItem.path(context.selectedSystemId),
      };
    });

  return (
    <div role="menuitem" className="hamburgerMenuItem">
      <div className="hamburgerMenuItem__menuItemList">
        <div className="hamburgerMenuItem__menuItemHeading">
          <FormattedMessage id={id} defaultMessage={name} />
        </div>

        {filteredItems.map((menuItem) => (
          <HamburgerSubMenuItem
            key={menuItem.id}
            menuItem={menuItem}
            redirectTokenFunc={context.redirectTokenFunc}
            selectedSystem={selectedSystem}
            logoutUser={logoutUser}
          />
        ))}
      </div>
    </div>
  );
};

HamburgerSubMenu.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  onLinkClick: PropTypes.func,
  children: PropTypes.any,
  disabled: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  selectedSystem: PropTypes.object,
  logoutUser: PropTypes.func.isRequired,
};

export default HamburgerSubMenu;
