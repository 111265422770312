export const spinnerDarkColor = '#414042';

export const USER_SECURITY_LEVELS = {
  admin: 'admin',
  servicePartner: 'servicePartner',
  viewer: 'viewer',
  editor: 'editor',
  manager: 'manager',
  buyer: 'buyer',
};

export const KEYBOARD_KEYS = {
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  SPACE: 'Space',
};
